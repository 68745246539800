import { useEffect, RefObject } from "react";

export default (scrollRef: RefObject<HTMLElement>, onBottom: () => void) => {
    // on every render, check if we hit the bottom
    useEffect(() => {
        const el = scrollRef.current;
        if (el) {
            if (el.scrollTop >= el.scrollHeight - el.clientHeight) onBottom()
        }
    })

    // register new scroll event occasionally
    useEffect(() => {
        const el = scrollRef.current;
        if (el) {
            const onScroll = () => {
                if (el.scrollTop >= el.scrollHeight - el.clientHeight) onBottom()
            };
            el.addEventListener('scroll', onScroll);
            return () => { el.removeEventListener('scroll', onScroll); }
        }
    }, [onBottom, scrollRef])
};