import React, { useRef, useEffect, useContext, useState } from 'react'
import { Typography, Button, useTheme, Box, LinearProgress } from '@material-ui/core'
import useForceUpdate from '../hooks/useForceUpdate'
import ImagePreview from '../components/ImagePreview'
import { AuthContext } from '../components/auth/AuthContextProvider'
import imageUpload, { Response, fileListToArray } from '../util/imageUpload'

interface Props {
    onUpload: (res: Response | undefined) => void;
    startingFiles?: FileList
    multiple?: boolean;
    target?: string
    note?: string;
}

export default ({ onUpload, startingFiles, multiple, target, note }: Props) => {

    const forceUpdate = useForceUpdate()

    const theme = useTheme()

    const outlineBox = useRef<HTMLDivElement>(null);
    const inputRef = useRef<HTMLInputElement>(null);

    const [files, setFiles] = useState<FileList | null>(startingFiles ?? null);

    let previews = null;

    if (files) {
        previews = <Box display="flex" flexWrap="wrap" justifyContent="center">
            {fileListToArray(files).map(f => {
                return <Box p={1} key={f.name}>
                    <ImagePreview file={f} style={{ maxWidth: theme.spacing(20), maxHeight: theme.spacing(20) }} />
                </Box>
            })}
        </Box>
    }

    useEffect(() => {
        const input = inputRef.current;
        if (input) {
            input.files = files;
        }
    }, [files])

    useEffect(() => {
        const onPaste = (event: ClipboardEvent) => {
            if (event.clipboardData) {
                const files = event.clipboardData.files;
                if (files && files.length > 0) setFiles(files);
            }
        }
        document.addEventListener("paste", onPaste)
        return () => { document.removeEventListener("paste", onPaste); }
    }, [forceUpdate])

    const [authInfo] = useContext(AuthContext);

    const [progress, setProgress] = useState(-1);


    return <form onSubmit={async e => {
        const upload = imageUpload(files, authInfo, target, e => {
            setProgress(e.loaded / e.total);
        });
        e.preventDefault();
        const resp = await upload;
        setProgress(-1);
        onUpload(resp);
    }}>
        <div style={{ width: "100%", minHeight: theme.spacing(40), position: "relative", display: "flex" }}>
            <div style={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%", zIndex: 1, cursor: "pointer" }}
                onDrop={e => {
                    e.preventDefault();
                    const outer = outlineBox.current;
                    if (outer) outer.style.padding = "0";
                    setFiles(e.dataTransfer.files);
                }}
                onDragEnter={e => {
                    const outer = outlineBox.current;
                    if (outer) outer.style.padding = theme.spacing(2) + "px";
                }}
                onDragLeave={e => {
                    const outer = outlineBox.current;
                    if (outer) outer.style.padding = "0";
                }}
                onDragOver={e => {
                    e.preventDefault()
                }} onClick={() => {
                    inputRef.current!.click()
                }} />
            <div ref={outlineBox} style={{
                position: "absolute", top: 0, left: 0, width: "100%", height: "100%",
            }}>
                <div style={{ border: `6px dashed ${theme.palette.divider}`, width: "100%", height: "100%", }} />
            </div>
            <div style={{
                flexGrow: 1, alignSelf: "stretch", display: "flex", alignItems: "center",
                justifyContent: "center", flexDirection: "column", padding: theme.spacing(3)
            }}>
                <Box mb={1}>
                    <Typography variant="h4" align="center">Drop or paste {multiple ? "image" : "an image"} here to upload</Typography>
                </Box>
                <Button>Click to select file{multiple ? "s" : ""}</Button>
                <input type="file" name="images" ref={inputRef} style={{ display: "none" }}
                    multiple={multiple} accept="image/*"
                    onChange={e => setFiles(e.target.files)} />
                {previews}
            </div>
        </div>
        <Box p={1} textAlign="center">
            {note && <Typography>{note}</Typography>}
            {progress >= 0 && <Box py={2}>
                <LinearProgress value={progress * 100} variant="determinate" />
            </Box>}
            <Button disabled={!files?.length || progress >= 0} type="submit">Upload</Button>
        </Box>
    </form>
}