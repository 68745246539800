import React, { useCallback, useState, useRef, ReactNode } from 'react'
import useCommand, { triggerCommand, CommandTriggerEvent } from '../hooks/commands/useCommand';
import { Dialog, makeStyles, Typography, TextFieldProps } from '@material-ui/core';
import Autocomplete from '../components/Autocomplete';
import { CommandsLoose } from '../data/Commands';
import Spacer from '../components/auth/Spacer';
import KBD from '../components/KBD';
import PaletteField from '../components/PaletteField';

const useClasses = makeStyles(theme => ({
    dialogPaper: {
        overflow: "visible", // makes the textfield outline work a little better
        alignSelf: "flex-start",
        background: "none"
    }
}))

type ParameterAction = { parameter: { event: CommandTriggerEvent, index: number }, parameters: string[] }
type Action = ParameterAction;
type ParameterInput = { event: CommandTriggerEvent, values?: string[], prompt: string }

type OpenState = boolean | ParameterInput

export const CommandPaletteRef: { current?: (action: Action) => void } = {
    current: undefined
}

export default () => {

    const [open, setOpen] = useState<OpenState>(false);

    const openRef = useRef(open)
    openRef.current = open;

    useCommand("command_palette", useCallback(() => {
        if (!openRef.current) {
            setOpen(true);
            return true;
        }
        return false;
    }, []));

    useCommand("close", useCallback(() => {
        if (openRef.current) {
            setOpen(false);
            return true;
        }
        return false;
    }, []))

    CommandPaletteRef.current = (action: Action) => {
        if (action.parameter) {
            const event = action.parameter.event
            const parameters = event.command.parameters;
            if (parameters) {
                const param = parameters[action.parameter.index];
                const vals = param.values
                if (Array.isArray(vals)) {
                    setOpen({ event: action.parameter.event, prompt: param.prompt, values: vals })
                } else {
                    const v = vals as ((parameters: string[]) => string[]) | undefined;
                    setOpen({ event: action.parameter.event, prompt: param.prompt, values: v?.(action.parameters) })
                }
            }
        }
    }

    const classes = useClasses();

    const close = useCallback(() => { setOpen(false) }, []);

    let autoComplete: ReactNode;

    if (typeof open !== "boolean") { // sub command
        const values = open.values;
        const textFieldProps: Partial<TextFieldProps> = { placeholder: open.prompt, autoFocus: true, fullWidth: true }
        if (values) {
            autoComplete = <Autocomplete
                key={"parameterAutocomplete" + open.prompt}
                values={values.map(v => ({ display: v }))}
                onSelect={c => {
                    close();
                    triggerCommand({ ...open.event, parameters: [...(open.event.parameters ?? []), c.display] });
                }}
                display={c => <Typography>{c.display.substr(0, 1).toUpperCase() + c.display.substr(1)}</Typography>}
                TextFieldProps={textFieldProps} />
        } else { // free form input
            autoComplete = <PaletteField TextFieldProps={{
                ...textFieldProps, onKeyDown: (e: React.KeyboardEvent) => {
                    if (e.key === "Enter") {
                        const value = (e.target as HTMLInputElement).value;
                        close();
                        triggerCommand({ ...open.event, parameters: [...(open.event.parameters ?? []), value] });
                        e.preventDefault();
                    }
                }
            }} />
        }
    } else { // main command list
        autoComplete = <Autocomplete
            key="paletteAutocomplete"
            values={CommandsLoose}
            onSelect={command => {
                setOpen(false);
                openRef.current = false;
                triggerCommand({ command });
            }}
            display={c => <><Typography>{c.display}</Typography><Spacer /><KBD keys={c.hotkeys} /></>}
            TextFieldProps={{ placeholder: "Enter command", autoFocus: true, fullWidth: true }} />
    }

    if (!open) return null;

    return <Dialog open={true} disablePortal fullWidth onBackdropClick={close} transitionDuration={0}
        classes={{ paper: classes.dialogPaper }}>
        {autoComplete}
    </Dialog>;
}