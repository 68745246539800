import React, { ReactNode } from 'react'
import { makeStyles } from '@material-ui/core';

declare global {
    interface ArrayConstructor {
        isArray<T>(arg: Iterable<T>): arg is Array<T>;
    }
}

const useClasses = makeStyles(theme => ({
    kbd: {
        background: theme.palette.background.paper,
        border: `1px solid ${theme.palette.divider}`,
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5),
        borderRadius: theme.spacing(0.5)
    },
    plus: {
        paddingLeft: theme.spacing(0.5),
        paddingRight: theme.spacing(0.5)
    },
    comma: {
        margin: 0
    }
}))

interface Props {
    keys?: string | Readonly<string[]>
}

const KBD = ({ keys }: Props) => {
    const classes = useClasses();

    if (keys === undefined) return null;
    if (Array.isArray(keys)) {
        if (keys.length === 1) keys = keys[0];
        if (keys.length === 0) return null;
    }
    if (typeof keys === "string") {
        const split = keys.split("+")
        const o: ReactNode[] = []
        split.forEach((s, i) => {
            o.push(<kbd className={classes.kbd} key={i}>{s.charAt(0).toUpperCase() + s.slice(1)}</kbd>)
            if (i !== split.length - 1) {
                o.push(<span className={classes.plus} key={i + "+"}>+</span>)
            }
        })
        return <>{o}</>
    } else {
        const out: ReactNode[] = []
        keys.forEach((k, i) => {
            out.push(<KBD key={k} keys={k} />)
            if (i !== keys!.length - 1) {
                out.push(<pre className={classes.comma} key={i}>, </pre>)
            }
        })
        return <>{out}</>;
    }
}

export default KBD