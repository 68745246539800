import React from "react"
import Card from "../../containers/Card"
import { Page } from "../../data/pages"
import { Typography, Divider, Link } from "@material-ui/core"
import Settings from "../../data/Settings"
import Loading from "../../containers/Loading"
import useEndpoint from "../../hooks/network/useEndpoint"

interface Props {
    page: Page
}

interface Changelog {
    author: string;
    message: string;
    time: number;
    url: string;
}

interface ChangeDate {
    date: number;
    changes: Changelog[]
}

export default ({ page }: Props) => {
    const [commits] = useEndpoint<Changelog[]>(Settings.Api.Changelog, { localStorageKey: Settings.LocalStorage.Changelog })

    if (commits) {

        const dates: ChangeDate[] = [];

        let lastDate = -1;
        commits.forEach(c => {
            const date = new Date(c.time).setHours(0, 0, 0, 0);
            // insert new date header
            if (date !== lastDate) {
                lastDate = date;
                dates.push({ date, changes: [c] })
            } else {
                const target = dates[dates.length - 1].changes
                const match = target.find(t => c.author === t.author);
                // This author already made a change on this day, so lets just merge the commit messages
                if (match) {
                    match.message = match.message + "\n" + c.message
                } else {
                    target.push(c)
                }
            }
        })


        return <Card>
            {dates.map(d => <React.Fragment key={d.date}>
                <Typography variant="h4">
                    {new Date(d.date).toLocaleDateString()}
                </Typography>
                <Divider />
                {d.changes.map(c => <React.Fragment key={c.author}>
                    <Link href={c.url} target="_blank" rel="noopener"><Typography variant="h6">{c.author}</Typography></Link>
                    <ul style={{ margin: 0 }}>
                        {c.message.split('\n').map((s, i) => <li key={i}>
                            <Typography variant="body1">{s}</Typography>
                        </li>)}
                    </ul>
                </React.Fragment>)}
            </React.Fragment>)}
        </Card >
    } else {
        return <Loading />
    }
}