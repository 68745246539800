import React, { useState, useEffect, useContext } from "react"
import Card from "../../containers/Card"
import { Page } from "../../data/pages"
import { Typography, TextField, Box, Button, useTheme, Divider } from "@material-ui/core"
import useForceUpdate from "../../hooks/useForceUpdate"
import { subscribeUser, unsubscribeUser } from "../../service/subscribe"
import { AuthContext } from "../../components/auth/AuthContextProvider"
import Settings from "../../data/Settings"
import { fetchEndpoint } from "../../util/network"
import { wrapTooltip } from "../../util/wrapTooltip"

interface Props {
    page: Page
}

export default ({ page }: Props) => {
    const forceUpdate = useForceUpdate()
    const theme = useTheme();

    const token = localStorage.getItem(Settings.LocalStorage.ServerFcmToken)

    const [message, setMessage] = useState<string | undefined>()

    const [authInfo,] = useContext(AuthContext);

    const permission = Notification.permission;

    // listen for permission changes
    useEffect(() => {
        let cancelled = false;
        let perm: PermissionStatus | undefined;
        const handler = () => { forceUpdate() }
        const query = async () => {
            perm = await navigator.permissions.query({ name: "notifications" })
            if (!cancelled) perm.addEventListener("change", handler)
        }
        query();
        return () => {
            cancelled = true;
            if (perm) perm.removeEventListener("change", handler);
        }
    }, [forceUpdate])

    return <Card>
        <Typography variant="h4" align="center">{page.label}</Typography>
        <Box p={1} display="grid" alignItems="center" gridTemplateColumns="repeat(2, max-content)" gridGap={theme.spacing(1)}>
            <Typography variant="body1">
                Current Status:
            </Typography>
            <TextField value={permission} variant="outlined" InputProps={{ readOnly: true }} />
            <Typography variant="body1">
                Max Actions:
            </Typography>
            <TextField value={Notification.maxActions} variant="outlined" InputProps={{ readOnly: true }} />
        </Box>
        <Box p={1}>
            {wrapTooltip(<Button disabled={permission !== "default"} onClick={() => { Notification.requestPermission() }}>
                Request Permission</Button>, permission !== "default" ? `Permission already ${permission}` : undefined, true)}
            {permission === "denied" && " Please enable notifications in your browser"}
        </Box>
        {authInfo && <>
            <Box p={1} display="flex">
                {wrapTooltip(<Button disabled={permission === "denied"} onClick={async () => {
                    setMessage("Subscribing");
                    setMessage(await subscribeUser());
                }}>Subscribe</Button>, 'This button is automatically "pressed" when logging in')}
                <Box p={1}></Box>
                {wrapTooltip(<Button onClick={async () => {
                    setMessage(await unsubscribeUser())
                }}>Unsubscribe</Button>, 'This button is automatically "pressed" when logging out')}
                <Box p={1}></Box>
                {wrapTooltip(<Button onClick={() => {
                    fetchEndpoint(Settings.Api.Notifications.Test, { authInfo, requireAuth: true })
                }}>Test Notify</Button>, "Sends notification to all tokens registered to the current user")}
            </Box>
        </>
        }
        {token && <Box p={1}><Typography variant="body1" style={{ wordBreak: "break-all" }}>Current token: {token}</Typography></Box>}
        {message && <><Divider /><Box p={1}><Typography variant="body1">{message}</Typography></Box></>}
    </Card >
}