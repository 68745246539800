import React, { useContext, useCallback, useState, useRef, createContext, useEffect } from "react"
import { AuthContext } from "../components/auth/AuthContextProvider"
import Comment from "../containers/comments/Comment"
import useScrolledToBottom from "../hooks/useScrolledToBottom"
import Loading from "../containers/Loading"
import { Comment as CommentType } from "../containers/comments/Comment"
import PostComment from "../containers/comments/PostComment"
import { getComments, deleteComment } from "../util/api"
import { Box, Divider, Typography } from "@material-ui/core"
import { UNAUTHORIZED } from "../util/network"
import { useParams } from "react-router-dom"
import Settings from "../data/Settings"
import useEndpoint from "../hooks/network/useEndpoint"
import { LayoutContext } from "../containers/Layout"
import { logout } from "./Login"

const inc = (i: number) => i + 1

export interface CommentsPageInterface {
    appendToEditor: (text: string) => void;
}

// NOTE: this context pattern is not recommended for general use, it just happened to save a lot of code here
export const CommentsPageContext = createContext<CommentsPageInterface>({ appendToEditor: () => { } })

export default () => {
    const [authInfo, updateAuthInfo] = useContext(AuthContext);
    const [extraComments, setExtraComments] = useState<CommentType[]>([]);

    const [forceReload, setForceReload] = useState(0);

    const layoutContext = useContext(LayoutContext);

    const initialPage = useParams<{ initialPage?: string }>().initialPage;

    const currentPage = useRef(initialPage !== undefined ? Number(initialPage) : 0);
    const loading = useRef<object | undefined>();

    const onNetworkError = useCallback(e => {
        if (e.name === UNAUTHORIZED.toString()) {
            logout(updateAuthInfo, false);
        }
        console.error(e);
    }, [updateAuthInfo])

    const firstUrl = initialPage !== undefined ? Settings.Api.Comment + "?page=" + initialPage : Settings.Api.Comment;

    const [firstPage, isCached] = useEndpoint<CommentType[]>(firstUrl,
        {
            authInfo, requireAuth: true, localStorageKey: Settings.LocalStorage.Comments, forceSend: forceReload,
            onError: onNetworkError
        })

    useEffect(() => {
        // TODO eventually we should widen this condition, but it would take some sketchy stuff
        if (initialPage === undefined && extraComments.length === 0) {
            const channel = new BroadcastChannel("new-comment");
            channel.onmessage = function () { setForceReload(e => e + 1); }
            return () => { channel.close() }
        }
    }, [initialPage, extraComments.length])

    useScrolledToBottom(layoutContext.scrollRef,
        useCallback(async () => {
            if (!isCached && !loading.current) {
                if (authInfo) {
                    try {
                        const token = {}
                        loading.current = token;
                        const newComments = await getComments(authInfo, currentPage.current + 1)
                        if (loading.current === token) { // make sure we didn't get cancelled
                            currentPage.current++
                            loading.current = undefined;
                            setExtraComments(comments => [...comments, ...newComments])
                        }
                    } catch (e) {
                        onNetworkError(e);
                    }
                }
            }
        }, [authInfo, isCached, onNetworkError]));

    const reload = () => {
        currentPage.current = 0;
        loading.current = undefined; // this will cancel all pending requests
        setExtraComments([]);
        setForceReload(inc);
    }

    const deleteCommentAuthWrapper = async (_id: string) => {
        if (authInfo) {
            await deleteComment(authInfo, _id)
            reload();
        }
    }

    const renderComment = (comment: CommentType) => <Comment key={comment._id} comment={comment}
        deleteComment={deleteCommentAuthWrapper}
        canDelete={authInfo?.admin || comment.username === authInfo?.username} />

    return <CommentsPageContext.Provider value={{ appendToEditor: () => { } }}>
        <PostComment afterPost={reload} />
        <Divider />
        {initialPage !== undefined && <Box p={1}><Typography align="center">Page: {initialPage}</Typography></Box>}
        {(isCached || !firstPage) && <Loading cached={Boolean(firstPage)} />}
        {firstPage?.map(renderComment)}
        {extraComments.map(renderComment)}
    </CommentsPageContext.Provider>
}