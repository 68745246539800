import React, { useState, useEffect, useContext } from "react"
import Card from "./Card";
import { Box, Button, Typography } from "@material-ui/core";
import Settings from "../data/Settings";
import { fetchCallback } from "../util/network";
import { AuthContext } from "../components/auth/AuthContextProvider";
import ImageIcon from '@material-ui/icons/Image';
import ImageUploadView from "./ImageUploadView";
import ConfirmationModal from "../components/ConfirmationModal";
import pages from "../data/pages";
import { useHistory } from "react-router-dom";
import { logout } from "../pages/Login";

interface Props {
    username: string
}

export default ({ username }: Props) => {
    const [commentCount, setCommentCount] = useState<number | undefined>()
    useEffect(() => {
        fetchCallback<number>(Settings.Api.CommentCount + `?username=${encodeURI(username)}`, data => {
            if (typeof data === "number")
                setCommentCount(data)
        })
    }, [username])

    const [authInfo, updateAuthInfo] = useContext(AuthContext);
    const history = useHistory();
    const self = username === authInfo?.username;
    const [uploadDialog, setUploadDialog] = useState<boolean>(false);

    return <div>
        {uploadDialog && <ConfirmationModal onClose={() => setUploadDialog(false)} confirm={""} deny="close">
            <ImageUploadView startingFiles={typeof uploadDialog === "boolean" ? undefined : uploadDialog}
                note="Your image will be resized to a lossy WebP at 144x144 with cover centering. For the best results, upload a square high-resolution lossless image."
                target={Settings.Api.ProfilePicture}
                onUpload={_ => {
                    setUploadDialog(false);
                    window.location.reload();
                }} />
        </ConfirmationModal>}
        <Card username={username} hasImage={true}>
            <Typography variant="h5">{username}</Typography>
            <Typography variant="caption">Comments posted: {commentCount}</Typography>
            {self && <>
                <Box pt={2}>
                    <Button onClick={() => setUploadDialog(true)}
                        startIcon={<ImageIcon />}>Upload Avatar</Button>
                </Box>
                <Box pt={2}>
                    <Button onClick={async () => {
                        await logout(updateAuthInfo);
                        history.push(pages.login.path);
                    }}>
                        Logout
                    </Button>
                </Box></>}
        </Card>
    </div>
}