import React, { createContext, ReactNode, useState } from "react"
import { ThemeProvider, createMuiTheme, ThemeOptions } from "@material-ui/core"

type CustomThemeOptions = {
    primary: string;
    secondary: string;
    type: "dark" | "light";
    bg: string | undefined;
    container: string | undefined;
} | undefined

export const CustomThemeContext = createContext<(newTheme: CustomThemeOptions) => void>(() => { })

interface Props {
    children: ReactNode;
    theme: ThemeOptions;
}

export default ({ children, theme }: Props) => {
    const [customTheme, setCustomTheme] = useState<CustomThemeOptions>()

    const muiTheme = customTheme ? {
        ...theme,
        palette: {
            ...theme.palette,
            primary: { main: customTheme.primary },
            secondary: { main: customTheme.secondary },
            type: customTheme.type,
            background: {}
        }
    } : theme;
    if (customTheme?.bg) {
        muiTheme.palette!.background!.default = customTheme.bg;
    }
    if (customTheme?.container) {
        muiTheme.palette!.background!.paper = customTheme.container;
    }

    const child = <ThemeProvider theme={createMuiTheme(muiTheme)}>{children}</ThemeProvider>


    return <CustomThemeContext.Provider value={(newTheme: CustomThemeOptions) => { setCustomTheme(newTheme) }}>
        {child}
    </CustomThemeContext.Provider>
}