import { useEffect } from "react";
import Hotkeys from "../../data/hotkeys";
import { CommandName, Command, CommandsLoose } from "../../data/Commands";
import { CommandPaletteRef } from "../../containers/CommandPalette";


export interface CommandTriggerEvent {
    keyboardEvent?: KeyboardEvent
    command: Command
    parameters?: string[]
}

type Handler<T extends CommandName> = (e: CommandTriggerEvent) => boolean
const commandHandlers: { [commandName in CommandName]?: Set<Handler<commandName>> } = {}

export function triggerCommand(event: CommandTriggerEvent | CommandName) {
    if (typeof event === "string") {
        for (const e of CommandsLoose) {
            if (e.name === event) {
                event = { command: e };
                break;
            }
        }
        if (typeof event === "string") return false;
    }
    const command = event.command
    if (command.parameters?.length !== event.parameters?.length) {
        CommandPaletteRef.current?.({ parameter: { event, index: event.parameters?.length ?? -1 + 1 }, parameters: event.parameters ?? [] })
        return true;
    } else {
        if (command.handler) {
            command.handler(event);
            return true;
        }
        const handlers = commandHandlers[command.name];
        if (handlers) {
            for (const x of handlers) {
                if (x(event)) {
                    return true; // inform the command triggerer that the event was consumed
                }
            }
        }
    }
    return false;
}

// keyboard is one method to trigger commands
document.addEventListener("keydown", e => {
    if (!e.key) { return }
    const keyArray = [];
    if (e.ctrlKey) keyArray.push("ctrl");
    if (e.shiftKey) keyArray.push("shift");
    if (e.altKey) keyArray.push("alt");
    keyArray.push(e.key.toLowerCase());
    const keyString = keyArray.join("+");
    const command = Hotkeys[keyString]
    if (command) {
        // if the hotkey isn't a ctrl or alt key, we need to make sure the user isn't trying to type something
        // Multi character key presses ignore this (ex `Escape` or `Tab`)
        if (!e.ctrlKey && !e.altKey && e.key.length === 1) {
            if (e.target) {
                if (e.target instanceof HTMLInputElement || e.target instanceof HTMLTextAreaElement) {
                    return;
                }
            }
        }
        if (triggerCommand({ command: command, keyboardEvent: e })) e.preventDefault();
    }
});

/**
 * Listens to a given command and calls the handler when that command triggers via any method
 */
const useCommand = <T extends CommandName>(commandName: T, handler: Handler<T>) => {
    useEffect(() => {
        if (commandHandlers[commandName] === undefined) commandHandlers[commandName] = new Set<Handler<T>>();
        commandHandlers[commandName]!.add(handler);
        return () => { commandHandlers[commandName]!.delete(handler); }
    }, [commandName, handler]);
}
export default useCommand