import React, { useContext } from "react"
import { Paper, makeStyles, createStyles, Link, Typography } from "@material-ui/core"
import Divider from "@material-ui/core/Divider"
import { Link as RouterLink } from "react-router-dom";
import { LayoutContext } from "./Layout";
import UserAvatar from "./UserAvatar";
import { Page } from "../data/pages";

interface Props {
    username?: string;
    children: React.ReactNode;
    noPadding?: boolean
    lazy?: boolean;
    hasImage?: boolean;
    noWidth?: true;
    page?: Page
}

const styleFactory = (shrink: number) => makeStyles(theme => {
    const spacing = shrink >= 2 ? 1 : 2;
    return createStyles({
        card: {
            overflowX: "auto",
            flex: (props: any) => props.noWidth ? undefined : "0.2 1 45em",
            position: "relative"
        },
        root: {
            display: "flex",
            margin: theme.spacing(2),
            justifyContent: "center"
        },
        cardColumns: {
            display: "flex",
            alignItems: "stretch"
        },
        profilePictureWrapper: {
            padding: theme.spacing(spacing),
            display: "flex",
            alignItems: "flex-start"
        },
        profilePicture: {
            width: theme.spacing(shrink >= 2 ? 7 : 9),
            height: theme.spacing(shrink >= 2 ? 7 : 9)
        },
        bodyWrapper: {
            flex: 1,
            display: "flex",
            flexDirection: "column",
            padding: theme.spacing(spacing)
        },
        bodyWrapperNoPadding: {
            flex: 1,
            display: "flex",
            flexDirection: "column",
            overflowX: "hidden"
        }
    })
});

const useStylesShrink0 = styleFactory(0);
const useStylesShrink2 = styleFactory(2);

export default ({ username, children, noPadding, lazy, hasImage, page, ...rest }: Props) => {
    const shrink = useContext(LayoutContext).shrink; // we use a context so that each card isn't media watching
    const classes = shrink >= 2 ? useStylesShrink2(rest) : useStylesShrink0(rest); // I think this is okay 😊

    return <div className={classes.root}>
        <Paper className={classes.card}>
            {username ?
                <div className={classes.cardColumns}>
                    <div className={classes.profilePictureWrapper}>
                        <Link component={RouterLink} to={`/members/${username}`} style={{ textDecoration: "none" }} >
                            <UserAvatar className={classes.profilePicture} user={username} hasImage={hasImage} />
                        </Link>
                    </div>
                    <Divider orientation="vertical" flexItem />
                    <div className={noPadding ? classes.bodyWrapperNoPadding : classes.bodyWrapper}>
                        {children}
                    </div>
                </div>
                :
                <div className={noPadding ? classes.bodyWrapperNoPadding : classes.bodyWrapper}>
                    {page?.label && <Typography variant="h4" align="center">{page.label}</Typography>}
                    {children}
                </div>
            }
        </Paper>
    </div>
}