import React, { useState, useContext, useEffect, useRef } from 'react'
import ChatDisplay from './ChatDisplay'
import { AuthContext } from '../../components/auth/AuthContextProvider';
import { fetchEndpoint } from '../../util/network';
import Settings from '../../data/Settings';
import useLocalStorageState from '../../hooks/useLocalStorageState';
import { useRouteMatch, useHistory, generatePath, Redirect } from 'react-router-dom';
import pages from '../../data/pages';
import ReactDOM from 'react-dom';
import useEndpoint from '../../hooks/network/useEndpoint';

export interface ChatItem {
    user?: string;
    message: string;
    time: number;
    pending?: boolean;
}
interface ApiChat {
    body: string,
    createdOn: number,
    sent: boolean
}

interface ChatPayload {
    body: string;
    createdOn: number;
    from: string;
    type: "chat"
}

async function postChat(to: string, body: string) {
    const res = await fetchEndpoint(Settings.Api.Chat, { method: "post", body: { to, body } });
    if (res) {
        const createdOn: number | undefined = JSON.parse(res).createdOn;
        return createdOn;
    }
}

async function getChat(other: string, page = 0): Promise<ChatItem[]> {
    let res;
    try {
        res = await fetchEndpoint(`${Settings.Api.Chat}?other=${other}`);
    } catch (e) {
        console.error(e);
        return [];
    }
    if (res) {
        const chats: ApiChat[] = JSON.parse(res);
        return chats.map(e => ({
            message: e.body,
            time: e.createdOn,
            user: e.sent ? undefined : other
        }));
    } else {
        console.error(res);
        throw new Error("Failed to get chat");
    }
}

async function sendActiveChat(e: string) {
    await fetchEndpoint(Settings.Api.ActiveChat, { body: { username: e }, method: "post" })
}

interface Props {
    fullPageButton?: boolean;
    autoFocus?: boolean;
    disableRoutes?: boolean;
}

export interface ChatUser {
    username: string;
    hasImage?: boolean;
}

function moveToTop(userList: ChatUser[], username: string) {
    for (let i = 0; i < userList.length; i++) {
        if (userList[i].username === username) {
            userList.unshift(userList.splice(i, 1)[0]);
            return // exit since we completed the move
        }
    }
    // we didn't find a user to move, so we create one
    userList.splice(0, 0, { username, hasImage: true });
}

function insertToTop(userList: ChatUser[], username: string) {
    for (let i = 0; i < userList.length; i++) {
        if (userList[i].username === username) {
            return // exit since we don't need to insert
        }
    }
    // we didn't find a user, so we create one
    userList.splice(0, 0, { username, hasImage: true });
}


// primary focus of this component is to manage the local chats data
export default ({ fullPageButton, autoFocus, disableRoutes }: Props) => {
    // const liveBackend: "push" | "ws" = "push";

    const chats = useRef<{ [key in string]?: ChatItem[] }>({}).current;
    const [authInfo] = useContext(AuthContext);
    const [lsUser, setLsUser] = useLocalStorageState<string | undefined>(undefined, "selected-chat-user");
    const other = useRouteMatch<{ other?: string }>().params.other ?? lsUser;
    const activeUsers = useEndpoint<ChatUser[]>(Settings.Api.ActiveChat,
        { localStorageKey: Settings.LocalStorage.ActiveChat })[0] ?? []

    const history = useHistory();
    const selectUser = (other: string | undefined, isNew: boolean) => {
        ReactDOM.unstable_batchedUpdates(() => {
            if (isNew && other) {
                moveToTop(activeUsers, other);
            }
            setLsUser(other)
            if (!disableRoutes) {
                if (other !== undefined) {
                    history.push(generatePath(pages.chat.routePath!, { other }));
                } else {
                    history.push(pages.chat.path);
                }
            }
            if (isNew && other) {
                sendActiveChat(other); // don't await
            }
        })
    }
    const [, setForceRender] = useState(0);

    if (other) {
        insertToTop(activeUsers, other)
    }


    const messages = other ? chats[other] : undefined;

    useEffect(() => {
        const channel = new BroadcastChannel("new-chat");
        channel.onmessage = function (e) {
            const payload: ChatPayload = e.data;
            let chat = chats[payload.from];
            if (!chat) { chat = []; chats[payload.from] = chat; }
            // we have to find where it goes since we don't want to insert them out of order
            let pos = 0;
            for (let i = 0; i < chat.length; i++) {
                if (chat[i].time > payload.createdOn) {
                    pos = i + 1;
                } else {
                    break;
                }
            }
            chat.splice(pos, 0, {
                message: payload.body,
                time: payload.createdOn,
                user: payload.from
            });
            setForceRender(e => e + 1);
        }
        return () => { channel.close() }
    }, [chats])

    useEffect(() => {
        if (other && chats[other] === undefined) {
            (async () => {
                const newChats = await getChat(other);
                if (chats[other] === undefined) {
                    chats[other] = newChats;
                    setForceRender(e => e + 1);
                }
            })();
        }
    }, [other, chats])


    useEffect(() => {
        const handler = (e: MessageEvent) => {
            if ("from" in e.data) {
                const target = generatePath(pages.chat.routePath!, { other: e.data.from });
                if (history.location.pathname !== target) {
                    history.push(target);
                }
            }
        }
        navigator.serviceWorker.addEventListener("message", handler)
        return () => { navigator.serviceWorker.removeEventListener("message", handler) }
    }, [history])

    if (history.location.pathname === pages.chat.path && other) {
        return <Redirect to={generatePath(pages.chat.routePath!, { other })} />
    }


    return <ChatDisplay
        autoFocus={autoFocus} fullPageButton={fullPageButton}
        selectedUser={other}
        selectUser={selectUser}
        removeUser={username => {
            let remove = activeUsers.findIndex(e => e.username === username);
            if (remove !== -1) {
                activeUsers.splice(remove, 1);
                fetchEndpoint(Settings.Api.ActiveChat, { method: "delete", body: { username } }) // don't await
                if (activeUsers.length > remove) {
                    selectUser(activeUsers[remove].username, false);
                } else if (activeUsers.length > 0) {
                    selectUser(activeUsers[remove - 1].username, false);
                } else {
                    selectUser(undefined, false);
                }
            }
        }}
        sendMessage={async (message: string) => {
            if (authInfo && other) {
                let newChats: ChatItem[];
                const newChat: ChatItem = { message, time: Date.now(), pending: true };
                const o = chats[other];
                if (o) {
                    newChats = [newChat, ...o];
                } else {
                    newChats = [newChat];
                }
                const post = postChat(other, message);
                chats[other] = newChats;
                moveToTop(activeUsers, other);
                const res = await post;
                if (res) {
                    newChat.time = res;
                    newChat.pending = undefined;
                    setForceRender(e => e + 1);
                }
            }
        }}
        users={activeUsers}
        messages={messages} />
}