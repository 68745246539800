import React from "react"
import { Route, useLocation, matchPath } from "react-router-dom"
import NotFound from "./pages/NotFound"
import pages, { Page } from "./data/pages"
import AuthView from "./components/auth/AuthView"
import Login from "./pages/Login"
import Layout from "./containers/Layout"
import useRouteCommands from "./hooks/commands/useRouteCommands"
import preferences from "./data/preferences"

// Renders all the routing information from data/pages.ts

export default () => {

    const location = useLocation();

    // We manually match here instead of using a <Switch> so that we can get the LayoutProps from the matched PageInfo
    // This is important because if we used a switch with Routes with the Layout inside of the Route,
    //   it would remount Layout each time the navigation changed, which resets any state in the layout.
    // Written with reference to https://github.com/ReactTraining/react-router/blob/master/packages/react-router/modules/Switch.js

    let match: Page | null = null;

    const pagesArray = Object.values(pages);

    for (const i in pagesArray) {
        const page = pagesArray[i];
        const matched = matchPath(location.pathname, { path: page.routePath ?? page.path, exact: page.exact });
        if (matched) {
            match = page
            break;
        }
    }

    useRouteCommands();

    // we render with a Route here to update the route match context
    return <Layout disableChatWidget={match?.disableChatWidget || preferences["Chat Widget"].value === "Disable"}>
        {match ?
            (() => {
                const page = match
                const el = React.createElement(page.component, { page })
                const inner = page.verified ? <AuthView role="verified" notAuthorized={<Login />}>{el}</AuthView> :
                    page.admin ? <AuthView role="verified" notAuthorized={<Login />}>{el}</AuthView> :
                        el
                return <Route path={page.routePath ?? page.path} exact={page.exact}>{inner}</Route>
            })()
            : <NotFound />}
    </Layout>


}