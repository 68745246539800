import Index from "../pages/Index"
import Comments from "../pages/Comments"
import FunStuff from "../pages/FunStuff"
import Members from "../pages/Members"
import Theme from "../pages/FunStuff/ThemeDesigner"
import BrushIcon from '@material-ui/icons/Brush';
import Login from "../pages/Login"
import EmojiEmotionsIcon from '@material-ui/icons/EmojiEmotions';
import Emotes from "../pages/FunStuff/Emotes"
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import Changelog from "../pages/FunStuff/Changelog"
import Notifications from "../pages/FunStuff/Notifications"
import NotificationsIcon from '@material-ui/icons/Notifications';
import Chat from "../pages/FunStuff/Chat"
import Upload from "../pages/FunStuff/Upload"
import PublishIcon from '@material-ui/icons/Publish';
import Images from "../pages/FunStuff/Images"
import ImageIcon from '@material-ui/icons/Image';
import MAL from "../pages/FunStuff/MAL"
import React from "react"
import Profile from "../pages/Profile"


interface TabInfo {
    exact?: boolean
}

export interface Page {
    label: string;
    path: string;
    tab?: TabInfo;
    component: React.ComponentType<{ page: Page }>;
    icon?: React.ComponentType<any>;
    children?: Page[]
    // for routing, set to true if unsure
    exact?: boolean;
    // for the navbar tab highlighting, ignores exact, leave undefined if not a tab
    tabExact?: boolean;

    // Client side restrictions (not for security)
    verified?: boolean;
    admin?: boolean;
    routePath?: string;

    // Layout
    disableChatWidget?: boolean;
}

const fun: Page = { label: "Fun Stuff", path: "/fun", component: FunStuff, exact: true, tab: {} }

const funPages = applyPageType({
    themeDesigner: { label: "Theme Designer", path: fun.path + "/theme", component: Theme, icon: BrushIcon },
    emotes: { label: "Emotes", path: fun.path + "/emotes", component: Emotes, icon: EmojiEmotionsIcon },
    changelog: { label: "Changelog", path: fun.path + "/changelog", component: Changelog, icon: ChangeHistoryIcon },
    notifications: { label: "Notifications", path: fun.path + "/notifications", component: Notifications, icon: NotificationsIcon },
    upload: { label: "Upload Image", path: fun.path + "/upload", component: Upload, icon: PublishIcon },
    images: { label: "Images", path: fun.path + "/images", component: Images, icon: ImageIcon },
    chat: {
        label: "Chat", path: fun.path + "/chat", routePath: fun.path + "/chat/:other?",
        verified: true, component: Chat, icon: NotificationsIcon, disableChatWidget: true
    },
    mal: { label: "MyAnimeList API", path: fun.path + "/mal", component: MAL, icon: () => React.createElement("div", { style: { width: 24 } }, "MAL") }
})

fun.children = Object.values(funPages);

const pages = applyPageType({
    home: { label: "Home", path: "/", component: Index, exact: true, tab: { exact: true } },
    comments: { label: "Comments", path: "/comments", routePath: "/comments/:initialPage?", component: Comments, verified: true, tab: {} },
    fun,
    members: { label: "Members", path: "/members", routePath: "/members/:username?", component: Members, tab: {} },
    profile: { label: "Profile", path: "/profile", component: Profile },
    login: { label: "Login", path: "/login", component: Login },
    ...funPages
})

export function getPageList() {
    return Object.keys(pages).sort() as (keyof typeof pages)[]
}

// fixes up the typing of a `pages` object to be more friendly + better intellisense
// to see what this does, hover over `pages` and view the typing
function applyPageType<Keys extends string>(pages: { [key in Keys]: Page }) {
    return pages;
}

export default pages