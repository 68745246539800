import { useState, useEffect } from "react";

interface Options {
    defaultMatches?: boolean;
}

/**
 * Used in place of Material-UI useMediaQuery because that one triggers an extra render pretty much every time.
 * @param queryString string passed to window.matchMedia, should not be changed after first call.
 */
export default (queryString: string, { defaultMatches = false }: Options = {}) => {
    const [query] = useState(() => window?.matchMedia ? window.matchMedia(queryString) : undefined);

    const [matches, setMatches] = useState(query?.matches ?? defaultMatches);

    useEffect(() => {
        if (query) {
            const onChange = (ev: MediaQueryListEvent) => { setMatches(ev.matches); }
            query.addListener(onChange);
            return () => { query.removeListener(onChange); }
        }
    }, [query])


    return matches;
}