import React from 'react'
import Settings from '../../data/Settings'
import useEndpoint from '../../hooks/network/useEndpoint'
import { Dialog, makeStyles, Typography } from '@material-ui/core'
import Autocomplete from '../../components/Autocomplete'

interface Props {
    onClose: (user?: string) => void
}
const useClasses = makeStyles(theme => ({
    dialogPaper: {
        overflow: "visible", // makes the textfield outline work a little better
        alignSelf: "flex-start",
        background: "none"
    },
    absolute: {
        position: "absolute!important" as any
    }
}))

export default ({ onClose }: Props) => {
    const classes = useClasses();

    const [members] = useEndpoint<string[]>(Settings.Api.ChatUser)
    if (!members) return null;

    return <Dialog open={true} disablePortal fullWidth transitionDuration={0}
        onClose={() => onClose()}
        BackdropProps={{ classes: { root: classes.absolute } }}
        classes={{ paper: classes.dialogPaper, root: classes.absolute }}>
        <Autocomplete
            values={members.map(e => ({ display: e }))}
            onSelect={value => {
                onClose(value.display);
            }}
            display={c => <><Typography>{c.display}</Typography></>}
            TextFieldProps={{ placeholder: "Enter username", autoFocus: true, fullWidth: true }} />
    </Dialog>;
}