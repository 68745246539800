import React, { ReactNode, useContext } from "react";
import { useHistory } from "react-router-dom";
import { AuthContext } from "./AuthContextProvider";


interface Props {
    children: ReactNode;
    role?: "verified" | "admin";
    notAuthorized?: ReactNode;
    redirect?: string;
}

export default ({ children, role, notAuthorized, redirect }: Props) => {
    const history = useHistory();
    const [authInfo,] = useContext(AuthContext)
    if (authInfo) {
        if (role === "verified") {
            if (authInfo.verified) {
                return <>{children}</>;
            }
        } else if (role === "admin") {
            if (authInfo.admin) {
                return <>{children}</>;
            }
        } else {
            return <>{children}</>;
        }
    }

    if (redirect) {
        history.push(redirect);
        return null;
    } else {
        return notAuthorized ? <>{notAuthorized}</> : null;
    }
}