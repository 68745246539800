import React, { useRef, useEffect } from 'react'

type Props = {
    file: File
} & React.ImgHTMLAttributes<HTMLImageElement>

export default ({ file, ...rest }: Props) => {
    const imgRef = useRef<HTMLImageElement>(null)

    useEffect(() => {
        const img = imgRef.current;
        if (img) {
            const reader = new FileReader();
            reader.onload = e => {
                img.src = e.target!.result as string;
            }
            reader.readAsDataURL(file);
        }
    }, [file])


    return <img alt={file.name} ref={imgRef} {...rest}></img>
}