const amounts = [[60, "second"], [60, "minute"], [24, "hour"], [-1, "day"]] as const;

export default (seconds: number) => {
    let diff = (Date.now() - seconds) / 1000;
    if (diff < 30) {
        return "Now"
    } else {
        for (const i in amounts) {
            const v = amounts[i]
            if (diff < v[0] || v[0] === -1) {
                const r = Math.floor(diff)
                return `${r} ${v[1]}${r === 1 ? "" : "s"} ago`
            }
            diff = Math.floor(diff / v[0])
        }
    }
}