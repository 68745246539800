import { getPageList } from "./pages"
import Settings from "./Settings"
import preferences, { loadPreferences } from "./preferences";
import { CommandTriggerEvent } from "../hooks/commands/useCommand";

interface Parameter {
    prompt: string;
    values?: ((parameters: string[]) => string[]) | Readonly<string[]>
}

export interface Command {
    hotkeys?: Readonly<string[]>;
    display: string;
    name: CommandName;
    parameters?: Readonly<Parameter[]>
    handler?: (parameters: CommandTriggerEvent) => void | Promise<void>
}

export type CommandName = (typeof Commands)[number]["name"]

const Commands = [
    {
        name: "toggle_chat",
        hotkeys: ["alt+c"],
        display: "Toggle Chat"
    },
    {
        name: "close",
        hotkeys: ["escape"],
        display: "Close Dialog"
    },
    {
        name: "change_key",
        display: "Change Hotkey",
        parameters: [
            { prompt: "Command name", values: () => Commands.map((e: any) => e.display) },
            { prompt: "New hotkey" }
        ]
    },
    {
        name: "command_palette",
        hotkeys: ["ctrl+shift+p", "."],
        display: "Open Command Palette"
    },
    { name: "post_comment", display: "Post Comment", hotkeys: ["c"] },
    {
        name: "goto_page",
        hotkeys: ["ctrl+p"],
        display: "Go to Page",
        parameters: [{ prompt: "Page name", values: getPageList }]
    },
    {
        name: "goto_comment_page",
        hotkeys: [],
        display: "Go to Comment Page Number",
        parameters: [{ prompt: "Page number" }]
    },
    { name: "reset_keys", display: "Reset Keys" },
    {
        name: "reset_config", display: "Reset Preferences to Default", handler: () => {
            localStorage.removeItem(Settings.LocalStorage.Preferences);
            loadPreferences();
        }
    },
    {
        name: "config", display: "Configure preferences...",
        handler: (event: CommandTriggerEvent) => {
            const ps = event.parameters!;
            const p = ps[0] as keyof typeof preferences;
            preferences[p].value = ps[1];
            console.log(preferences);
            const nonDefaultPrefs: { [key in string]: string } = {};
            for (const n in preferences) {
                const p = preferences[n as keyof typeof preferences]
                if (p.value !== p.def) {
                    nonDefaultPrefs[n] = p.value!;
                }
            }
            localStorage.setItem(Settings.LocalStorage.Preferences, JSON.stringify(nonDefaultPrefs))
        },
        parameters: [
            { prompt: "Preference", values: Object.keys(preferences) },
            {
                prompt: "New value", values: (parameters: string[]) => {
                    const p = parameters[0] as keyof typeof preferences;
                    return preferences[p].values;
                }
            }
        ]
    }
] as const

// have to do this in 2 steps because we only want `as const` for the CommandName portion, not the keys
export const CommandsLoose: Readonly<Command[]> = Commands // use when looping

interface Override {
    command: string
    hotkeys: Readonly<string[]>
}

const hotkeyOverrides = localStorage.getItem(Settings.LocalStorage.Hotkeys)
let overrides: Override[] | undefined = hotkeyOverrides ? JSON.parse(hotkeyOverrides) : undefined;
if (overrides) {
    overrides.forEach(e => {
        CommandsLoose.forEach(c => {
            if (c.name === e.command) {
                c.hotkeys = e.hotkeys
            }
        })
    });
}

export function addHotkeyOverride(override: Override) {
    if (overrides) {
        let found = false;
        for (const e of overrides) {
            if (e.command === override.command) {
                found = true;
                e.hotkeys = override.hotkeys;
                break;
            }
        }
        if (!found) overrides.push(override);
    } else {
        overrides = [override]
    }
    localStorage.setItem(Settings.LocalStorage.Hotkeys, JSON.stringify(overrides))
}

export default Commands // use when manually indexing