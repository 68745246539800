const apiRoot = "/api" // will get sent to wherever proxy is configured

const Settings = {
    Api: {
        ProfilePicture: `${apiRoot}/profile-image`,
        Comment: `${apiRoot}/comment`,
        Chat: `${apiRoot}/chat`,
        ChatUser: `${apiRoot}/chat-user`,
        ActiveChat: `${apiRoot}/active-chat`,
        User: `${apiRoot}/user`,
        Login: `${apiRoot}/login`,
        Logout: `${apiRoot}/logout`,
        Image: `${apiRoot}/image`,
        CommentCount: `${apiRoot}/user/comment-count`,
        Notifications: {
            Test: `${apiRoot}/notifications`,
            Token: `${apiRoot}/notifications/token`
        },
        Changelog: `${apiRoot}/changelog`,
        Jikan: "https://api.jikan.moe/v3"
    },
    LocalStorage: {
        Comments: "comments",
        ServerFcmToken: "fcm-token-sent", // holds the token we currently think the server has
        Changelog: "changelog",
        Members: "members",
        Images: "images",
        AuthKey: "auth-session-token",
        CommentDraft: "comment-draft",
        MALCache: "mal-cache",
        Hotkeys: "hotkeys",
        ActiveChat: "active-chat",
        Preferences: "preferences"
    },
    Chat: {
        // time in ms for the chat date to show up
        // basically represents the maximum delay we would call a singular chat session
        // purely cosmetic, time info can still be retrieved with context menu options
        TimeDisplayDelay: 5 * 60 * 1000
    }
} as const



export default Settings