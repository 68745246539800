import React, { ReactNode, useCallback } from "react";
import Modal from "./Modal";
import { Button, makeStyles } from "@material-ui/core";
import useGlobalKeyDown from "../hooks/useGlobalKeyDown";

interface Props {
    children?: ReactNode;
    title?: ReactNode;
    confirm?: string;
    confirmIcon?: ReactNode;
    deny?: string;
    denyIcon?: ReactNode;
    onClose: (confirmed?: boolean) => void;
    extraButtons?: ReactNode;
}

const useStyles = makeStyles(theme => ({
    footer: {
        "& > *": { marginLeft: theme.spacing(2) }
    }
}))

export default ({ children, confirm = "Confirm", deny = "Deny", title, onClose,
    extraButtons, confirmIcon, denyIcon }: Props) => {
    const classes = useStyles();

    const denied = () => {
        if (onClose) onClose(false);
    }
    const confirmed = useCallback(() => {
        if (onClose) onClose(true);
    }, [onClose])

    useGlobalKeyDown(useCallback(e => {
        if (e.ctrlKey && e.keyCode === 13) {
            confirmed() // ctrl + enter will press the confirm button
            return true;
        }
        return false;
    }, [confirmed]))

    return <Modal
        onClose={denied}
        header={title}
        footer={<div className={classes.footer}>
            {extraButtons}
            {deny && <Button color="default" onClick={denied} startIcon={denyIcon}>{deny}</Button>}
            {confirm && <Button onClick={confirmed} startIcon={confirmIcon}>{confirm}</Button>}
        </div>}>
        {children}
    </Modal>
}