import React, { useEffect, useState, useCallback, useRef } from "react";
import { register } from "./serviceWorker";
import { Snackbar, IconButton, Button } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';

interface Props {
    children: React.ReactElement
}

export default ({ children }: Props) => {
    const [snack, setSnack] = useState(false)
    const handleClose = useCallback((_?: any, reason?: string) => {
        if (reason === "clickaway") return;
        setSnack(false);
    }, [])

    const forceReload = useRef<() => void>();

    useEffect(() => {
        register({
            url: process.env.NODE_ENV === 'production' ? "service-worker.js" : "",
            onUpdate: (registration) => {
                const waiting = registration.waiting;
                if (waiting) {
                    waiting.addEventListener("statechange", e => {
                        if (waiting.state === 'activated') {
                            window.location.reload(); // will reload ALL pages
                        }
                    })
                    forceReload.current = () => {
                        waiting.postMessage({ type: "SKIP_WAITING" });
                    }
                }
                setSnack(true)
            }
        });
    }, [])

    return <>{children}<Snackbar
        open={snack} message="New version available, close all tabs to update" onClose={handleClose}
        action={<>
            <Button color="primary" size="small" variant="text"
                onClick={() => { handleClose(); forceReload.current && forceReload.current() }}>
                Force
            </Button>
            <IconButton size="small" color="inherit" onClick={handleClose}>
                <CloseIcon fontSize="small" />
            </IconButton>
        </>} /></>;
}