import React, { useState } from 'react'
import Card from '../../containers/Card'
import { Typography, Box, Link } from '@material-ui/core'
import { Page } from '../../data/pages'
import { Response } from '../../util/imageUpload'
import ImageUploadView from '../../containers/ImageUploadView'

interface Props {
    page: Page
}

const renderLink = (s: string, i: number) => {
    return <Typography key={i}><Link href={`/image/${s}`} key={s}>{s}</Link></Typography>
}

export default ({ page }: Props) => {
    const [response, setResponse] = useState<Response | undefined>()

    return <Card>
        <Typography variant="h4" align="center">{page.label}</Typography>
        <Box pb={1}></Box>
        <ImageUploadView multiple onUpload={res => {
            if (res) setResponse(res);
        }} />
        <Box>
            {response?.success.map(renderLink)}
            {response?.failed.map(renderLink)}
        </Box>
    </Card >
}