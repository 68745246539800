import { Command, CommandsLoose } from "./Commands";


const Hotkeys: { [key: string]: Command } = {};

CommandsLoose.forEach(command => {
    command.hotkeys?.forEach(key => {
        Hotkeys[key] = command;
    })
});

export default Hotkeys