import { AuthInfo } from "../components/auth/AuthContextProvider";

export const UNAUTHORIZED = 401
export const FORBIDDEN = 403

export type HttpMethod = "get" | "post" | "delete"

export interface FetchConfig {
    authInfo?: AuthInfo,
    requireAuth?: boolean,
    method?: HttpMethod,
    body?: any
}

/**
 * Non-async version of fetchEndpoint.
 * Use when you want to have a callback invoked after the fetch instead of using await.
 * Also useful because it is cancellable
 */
export function fetchCallback<T>(endpoint: string, callback: (data: T) => void, onError?: (error: Error) => void,
    config?: FetchConfig) {
    let canceled = false;
    (async () => {
        try {
            const data = await fetchEndpoint(endpoint, config)
            if (!canceled && data !== undefined) callback(JSON.parse(data));
        } catch (error) {
            if (!canceled) {
                if (onError) onError(error)
            }
        }
    })();
    return () => { canceled = true }
}

/**
 * Used primarily for REST actions other than GET. Especially if they require authentication.
 * 
 * If a component needs information to display on load, it should use either useEndpoint or useCacheEndpoint
 */
export async function fetchEndpoint(endpoint: string,
    { method = "get", body, authInfo, requireAuth = false }: FetchConfig = {}) {
    if (requireAuth && authInfo === undefined) {
        throw new Error(UNAUTHORIZED.toString());
    }
    const headers = new Headers();
    const init: RequestInit = { headers, method }
    if (body) {
        if (body instanceof FormData) {
            init.body = body;
        } else {
            headers.append("Content-Type", "application/json")
            init.body = JSON.stringify(body);
        }
    }
    const res = await fetch(endpoint, init);
    if (!res.ok) {
        const e = new Error(await res.text());
        e.name = res.status.toString();
        throw e;
    }
    try {
        return await res.text();
    } catch (e) {
        return undefined;
    }
}


export default { fetchCallback, fetchEndpoint }