import Settings from "./Settings";

interface Preference {
    def: string,
    value: string,
    values: string[]
}
function applyPreferenceType<T extends string>(e: { [key in T]: { def: string, values: string[] } }) {
    return e as { [key in T]: Preference };
}

const preferences = applyPreferenceType({
    "Chat Widget": {
        def: "Enable",
        values: ["Enable", "Disable"]
    }
})

export default preferences;

export function loadPreferences() {
    const s = localStorage.getItem(Settings.LocalStorage.Preferences);

    let json: { [key in string]?: string } = {};
    if (s) {
        try {
            json = JSON.parse(s);
        } catch {
            console.error("Failed to parse preferences from localStorage:", s);
        }
    }
    for (const key in preferences) {
        const p = preferences[key as keyof typeof preferences];
        p.value = json[key] ?? p.def;
    }
}
loadPreferences();