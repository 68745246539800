import React, { useContext } from "react"
import Card from "../containers/Card"
import { Typography, List, ListItem, ListItemIcon, ListItemText, Button } from "@material-ui/core"
import { Link } from "react-router-dom"
import { Page } from "../data/pages"
import { triggerCommand } from "../hooks/commands/useCommand"
import { AuthContext } from "../components/auth/AuthContextProvider"



export default ({ page }: { page: Page }) => {
    const [authInfo] = useContext(AuthContext);
    const verified = authInfo?.verified;
    const funPages = page.children;
    return <Card>
        <Typography variant="h4" align="center">{page.label}</Typography>
        <List>
            {funPages?.filter(e => !e.verified || verified).map(p => <ListItem
                key={p.label} button component={Link} to={p.path}>
                {p.icon && <ListItemIcon>
                    {React.createElement(p.icon)}
                </ListItemIcon>}
                <ListItemText primaryTypographyProps={{ variant: "h6" }} primary={p.label} color="primary.main" />
            </ListItem>)}
        </List>
        <div><Button onClick={() => { triggerCommand("command_palette") }}>Show Commands</Button></div>
    </Card>
}