import React, { useCallback, ReactNode } from "react";
import CloseIcon from '@material-ui/icons/Close';
import { Dialog, DialogTitle, DialogActions, makeStyles, DialogContent, IconButton, Typography } from "@material-ui/core";

interface Props {
    header?: React.ReactNode;
    footer?: React.ReactNode;
    isOpen?: boolean;
    center?: boolean;
    dialogTransform?: string;
    onClose?: () => void;
    children?: ReactNode;
}

const useStyles = makeStyles(theme => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
    dialogTitleRoot: {
        padding: theme.spacing(2),
        paddingRight: theme.spacing(7)
    },
    dialogContentRoot: {
        padding: theme.spacing(1)
    },
    dialogActionsRoot: {
        padding: theme.spacing(2)
    },
    container: {
        alignItems: "flex-start",
        display: "flex",
        justifyContent: "center"
    },
    paper: {
        overflowY: "unset"
    }
}));

export default ({ header, footer, onClose, children }: Props) => {
    const close = useCallback(() => {
        if (onClose) onClose();
    }, [onClose])

    const classes = useStyles();

    return <Dialog open={true} onClose={close} disablePortal fullWidth
        classes={{ container: classes.container, paper: classes.paper }} scroll="body">
        {header && <DialogTitle disableTypography classes={{ root: classes.dialogTitleRoot }}>
            <Typography variant="h6">{header}</Typography>
            <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
            </IconButton>
        </DialogTitle>}
        <DialogContent dividers classes={{ root: classes.dialogContentRoot }}>
            {children}
        </DialogContent>
        {footer && <DialogActions classes={{ root: classes.dialogActionsRoot }}>{footer}</DialogActions>}
    </Dialog>
}