import React, { useContext, useState, useRef, useEffect, useCallback } from "react"
import { Typography, TextField, Box, Button, FormControlLabel, Switch, ButtonGroup } from "@material-ui/core"
import Card from "../Card";
import { AuthContext } from "../../components/auth/AuthContextProvider";
import Spacer from "../../components/auth/Spacer";
import Comment from "./Comment"
import { postComment } from "../../util/api";
import { LayoutContext } from "../Layout";
import useReceiveMessage from "../../hooks/useReceiveMessage";
import { CommentsPageContext } from "../../pages/Comments";
import Settings from "../../data/Settings";
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmationModal from "../../components/ConfirmationModal";
import { useLocation } from "react-router-dom";
import { Response } from "../../util/imageUpload";
import ImageIcon from '@material-ui/icons/Image';
import ImageUploadView from "../ImageUploadView";
import useCommand from "../../hooks/commands/useCommand";

interface Props {
    afterPost: () => void;
}

export default ({ afterPost }: Props) => {
    const location = useLocation<any>();

    const [body, setBody] = useState(() => localStorage.getItem(Settings.LocalStorage.CommentDraft) ?? "");
    const [statusMessage, setStatusMessage] = useState<string | undefined>();
    const [showPreview, setShowPreview] = useState<boolean>(false);

    const inputRef = useRef<HTMLTextAreaElement>(null)
    useReceiveMessage("focusPost", useCallback(() => inputRef.current?.focus(), []))
    useEffect(() => {
        if (location.state?.forceFocus) {
            const input = inputRef.current;
            if (input) input.focus();
        }
    }, [location])
    useCommand("post_comment", useCallback(() => {
        const input = inputRef.current;
        if (input) input.focus();
        return true;
    }, []));

    const context = useContext(CommentsPageContext);
    // NOTE: don't do this normally, mutating the context is dangerous.
    // It is 'safe' here because if it messes up nothing bad will happen
    context.appendToEditor = (text: string) => {
        setBody(body => body + text);
        setTimeout(() => inputRef.current?.focus());
    }

    const shrink = useContext(LayoutContext).shrink >= 1

    const [authInfo,] = useContext(AuthContext);

    useEffect(() => { // thrown in an effect for a little bit of render speed
        localStorage.setItem(Settings.LocalStorage.CommentDraft, body)
    }, [body])

    const submit = async (e: React.FormEvent) => {
        e.preventDefault();
        if (authInfo) {
            setStatusMessage("Submitting...")
            try {
                const res = await postComment(body, authInfo);
                setStatusMessage(res);
                setBody("");
                afterPost();
            } catch (e) {
                if (e.message) {
                    setStatusMessage(e.message);
                }
            }
        }
    }

    const [uploadDialog, setUploadDialog] = useState<boolean | FileList>(false);

    const addUploadResult = (res: Response | undefined) => {
        if (res) {
            let append = "";
            res.success.forEach(i => append += `\n\n[📷](/image/${i})`);
            res.failed.forEach(i => append += `\n\n[📷](/image/${i})`);
            setBody(body => body + append)
        }
    }

    return <><Card username={shrink ? undefined : authInfo?.username} hasImage={authInfo?.hasImage}>
        <form onKeyDown={e => {
            if (e.key === "Enter" && e.ctrlKey) submit(e)
        }} onSubmit={submit}
            onPaste={(event) => {
                if (event.clipboardData.files && event.clipboardData.files.length > 0) {
                    setUploadDialog(event.clipboardData.files);
                }
            }}>
            <TextField value={body} onChange={e => { setBody(e.currentTarget.value) }}
                inputRef={inputRef}
                onFocus={e => {
                    // Sketchy but works
                    const val = e.target.value;
                    e.target.value = '';
                    e.target.value = val;
                }}
                color="secondary" variant="outlined" multiline fullWidth rowsMax={15}
                label="Write something beautiful" />
            <Box pt={1} display="flex" alignItems="center">
                <ButtonGroup>
                    <Button type="submit">Post</Button>
                    <Button onClick={() => setUploadDialog(true)} size="small"><ImageIcon /></Button>
                    {body && <Button onClick={() => setBody("")} size="small"><DeleteIcon /></Button>}
                </ButtonGroup>
                {statusMessage && <Box px={1}><Typography>{statusMessage}</Typography></Box>}
                <Spacer />
                <Box px={1}><FormControlLabel
                    control={<Switch
                        checked={showPreview}
                        onChange={e => setShowPreview(e.target.checked)}
                        color="primary" />}
                    label="Preview" labelPlacement="start" /></Box>
                <Typography variant="body2" align="right">{body.length} characters</Typography>
            </Box>
        </form>
    </Card>
        {showPreview && authInfo && <Comment
            canDelete={false} comment={{
                body, createdOn: Date.now(), username: authInfo.username, _id: "",
                hasImage: authInfo.hasImage
            }} />}
        {uploadDialog && <ConfirmationModal onClose={() => setUploadDialog(false)} confirm={""} deny="close">
            <ImageUploadView startingFiles={typeof uploadDialog === "boolean" ? undefined : uploadDialog}
                multiple
                onUpload={res => { setUploadDialog(false); addUploadResult(res) }} />
        </ConfirmationModal>}
        {/* {imagePreview && <ConfirmationModal onClose={() => setImagePreview("")}>
            <img src={imagePreview} alt="preview" style={{ maxWidth: "100%" }} /> */}
    </>
}