import React, { useState, ReactNode } from 'react'
import { TextFieldProps, Box, Paper, makeStyles, emphasize } from '@material-ui/core'
import PaletteField from './PaletteField'

type Props<T> = {
    values: Readonly<T[]>
    display: (t: T) => ReactNode
    onSelect: (t: T) => void
    TextFieldProps?: Partial<TextFieldProps>
}

type AutocompleteObject = Readonly<{ display: string }>

function filter<T extends AutocompleteObject>(list: Readonly<T[]>, filterString: string) {
    const filteredList = list.filter(o => {
        return o.display.toLowerCase().includes(filterString.toLowerCase());
    })
    return filteredList;
}
const useClasses = makeStyles(theme => {
    const optionBase = {
        paddingLeft: theme.spacing(1),
        paddingRight: theme.spacing(1),
        display: "flex",
        alignItems: "center",
        cursor: "pointer"
    }
    return {
        paper: {
            background: theme.palette.background.default
        },
        option: {
            ...optionBase,
            "&:hover": {
                background: emphasize(theme.palette.background.default),
                borderRadius: theme.shape.borderRadius,
            }
        },
        selectedOption: {
            ...optionBase,
            background: theme.palette.secondary.dark,
            "&:hover": {
                background: emphasize(theme.palette.secondary.dark)
            }
        }
    }
})

export default <T extends AutocompleteObject>({ values, display, onSelect, TextFieldProps }: Props<T>) => {

    const [state, setState] = useState<{
        value: string;
        target: T | undefined;
        filteredValues: Readonly<T[]>;
    }>({ value: "", target: values[0], filteredValues: values });

    const classes = useClasses();

    const updateValue = (newValue: string) => {

        setState(state => {
            const filteredValues = filter(values, newValue)
            const target = state.target && filteredValues.includes(state.target) ? state.target : filteredValues[0]
            return { value: newValue, target, filteredValues: filteredValues }
        })
    }


    const onKeyDown = (e: React.KeyboardEvent) => {
        const key = e.key;
        if (key === "Enter") {
            if (state.target) {
                onSelect(state.target);
            }
            e.preventDefault();
        } else if (key === "ArrowDown" || key === "ArrowUp" || key === "PageDown" || key === "PageUp" ||
            key === "Home" || key === "End") {
            if (state.target) {
                const values = state.filteredValues
                let index: number;
                if (key === "ArrowDown" || key === "ArrowUp") {
                    const offset = key === "ArrowDown" ? 1 : values.length - 1;
                    index = (values.indexOf(state.target) + offset) % values.length;
                } else if (key === "PageDown") {
                    // vscode does 17 for page keys
                    index = Math.min(values.indexOf(state.target) + 10, values.length - 1);
                } else if (key === "PageUp") {
                    index = Math.max(values.indexOf(state.target) - 10, 0);
                } else if (key === "End") {
                    index = values.length - 1;
                } else {
                    index = 0;
                }
                //  :
                // key === "Home" ? -state.target : 
                // key === 
                setState(state => ({ ...state, target: values[index] }));
            }
            e.preventDefault();
        }
    }

    return <>
        <PaletteField TextFieldProps={{
            ...TextFieldProps,
            value: state.value,
            onKeyDown: onKeyDown,
            onChange: e => updateValue(e.target.value)
        }} />
        <Paper className={classes.paper}>
            {state.filteredValues.map(v => <Box key={v.display}
                component={state.target === v ? Paper : undefined}
                className={state.target === v ? classes.selectedOption : classes.option}
                onClick={() => { onSelect(v) }}
            >{display(v)}</Box>)}
        </Paper>
    </>
}