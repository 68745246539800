import React from 'react'
import ChatBox from '../../containers/chat/ChatBox'
import { Paper } from '@material-ui/core'

interface ChatItem {
    user: string;
    message: string;
}


export default () => {

    return <Paper style={{ height: "100%" }}>
        <ChatBox />
    </Paper>
}