import React from 'react'
import { Avatar } from '@material-ui/core'

interface Props {
    user: string | undefined;
    size?: number;
    hasImage?: boolean;
    className?: string;
}

export function profilePictureUrl(user?: string) {
    return user ? `/image/profile/${user}.webp` : undefined;
}

export default ({ user, hasImage = true, size, className }: Props) => {
    return <Avatar src={hasImage ? profilePictureUrl(user) : undefined}
        className={className}
        alt={user} style={size ? { width: size, height: size } : undefined}>{user?.substring(0, 2)}</Avatar>
}