import React, { useState, useContext } from "react"
import Card from "../../containers/Card"
import { Typography, TextField, useTheme, Box, Button, recomposeColor, Switch, FormControlLabel } from "@material-ui/core"
import { Page } from "../../data/pages"
import { CustomThemeContext } from "../../components/CustomThemeProvider"
import colorNames from "../../data/colorNames"

function colorName(color: string): string
function colorName(color: string | undefined): string | undefined
function colorName(color: string | undefined) {
    return color === undefined ? color : color in colorNames ? recomposeColor({ type: 'rgb', values: colorNames[color] }) : color
}

interface ColorPicker {
    label: string;
    value: string;
    setValue: (value: string) => void;
}

export default ({ page }: { page: Page }) => {
    const theme = useTheme();

    let [primary, setPrimary] = useState(theme.palette.primary.main)
    let [secondary, setSecondary] = useState(theme.palette.secondary.main)
    let [bg, setBg] = useState<string | undefined>(undefined)
    let [container, setContainer] = useState<string | undefined>(undefined)

    primary = colorName(primary);
    secondary = colorName(secondary);
    bg = colorName(bg);

    const colorPickers: ColorPicker[] = [
        { label: "Primary Color", value: primary, setValue: setPrimary },
        { label: "Secondary Color", value: secondary, setValue: setSecondary },
        { label: "Background Color", value: bg ?? theme.palette.background.default, setValue: setBg },
        { label: "Container Color", value: container ?? theme.palette.background.paper, setValue: setContainer },
    ]

    let [type, setType] = useState(theme.palette.type);
    const setCustomTheme = useContext(CustomThemeContext);

    const saveTheme = () => {
        setCustomTheme({ primary, secondary, bg, type, container })
    }

    return <Card>
        <Typography variant="h4" align="center">{page.label}</Typography>
        {colorPickers.map(picker => <Box display="flex" key={picker.label}>
            <Box flex="1 0" p={1}>
                <TextField value={picker.value} onChange={e => picker.setValue(e.currentTarget.value)}
                    variant="outlined" label={picker.label} fullWidth />
            </Box>
            <Box flex="1" p={1}>
                <Box bgcolor={picker.value} height={1} />
            </Box>
        </Box>)}
        <Box>
            <FormControlLabel
                control={<Switch checked={type === 'dark'} onChange={() => setType(type => type === 'dark' ? 'light' : 'dark')}
                />}
                labelPlacement="start" label="Dark?" />
        </Box>
        <Box p={1}><Button onClick={saveTheme}>Apply</Button></Box>
    </Card>
}