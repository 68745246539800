import Settings from "../data/Settings";
import { AuthInfo } from "../components/auth/AuthContextProvider";


export interface Response {
    success: string[]
    failed: string[]
}

export function fileListToArray(files: FileList): File[] {
    const o: File[] = []
    for (let i = 0; i < files.length; i++) {
        const file = files.item(i);
        if (file) o.push(file);
    }
    return o;
}

export default async function (files: FileList | null | undefined, authInfo: AuthInfo | undefined,
    target: string = Settings.Api.Image, progress?: (progress: ProgressEvent) => void) {
    if (files) {
        const data = new FormData();
        for (let i = 0; i < files.length; i++) {
            const file = files.item(i)
            if (file) {
                data.append(file.type, file, file.name)
            }
        }


        return await new Promise<Response | undefined>(resolve => {
            const ajax = new XMLHttpRequest();
            if (progress) {
                ajax.upload.addEventListener("progress", progress);
            }
            ajax.addEventListener("error", () => {
                resolve(undefined);
            });
            ajax.addEventListener("load", e => {
                const res = ajax.responseText;
                if (res) {
                    try {
                        const json = JSON.parse(res) as Response;
                        resolve(json);
                    } catch {
                        resolve(undefined);
                    }
                }
            });
            ajax.open("POST", target);
            ajax.send(data);
        })
    }
}