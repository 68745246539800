// This file is for commands that can pretty much be handled from anywhere
import useCommand, { CommandTriggerEvent } from "./useCommand";
import { useCallback } from "react";
import { generatePath, useHistory } from "react-router-dom";
import pages from "../../data/pages";
import { CommandsLoose, addHotkeyOverride } from "../../data/Commands";
import Hotkeys from "../../data/hotkeys";
import Settings from "../../data/Settings";

function changeKey(event: CommandTriggerEvent) {
    const parameters = event.parameters;
    if (parameters) {
        const command = parameters[0]
        CommandsLoose.forEach(c => {
            if (c.display === command) {
                const newHotkey = parameters[1].toLowerCase().replace(/\s/g, "")
                const newHotkeys = newHotkey.split(",");

                // unbind old keys
                c.hotkeys?.forEach(e => { delete Hotkeys[e] });
                // bind new ones
                c.hotkeys = newHotkeys
                newHotkeys.forEach(e => Hotkeys[e] = c)
                // save to localStorage
                addHotkeyOverride({ command: c.name, hotkeys: c.hotkeys })
            }
        })
        return true;
    }
    return false;
}

function resetKeys() {
    localStorage.removeItem(Settings.LocalStorage.Hotkeys)
    return true;
}

export default () => {

    const history = useHistory();

    useCommand("goto_page", useCallback(event => {
        if (event.parameters) {
            history.push(pages[event.parameters[0] as keyof typeof pages].path, { forceFocus: true });
            return true;
        } else {
            return false;
        }
    }, [history]))

    useCommand("goto_comment_page", useCallback(event => {
        if (event.parameters) {
            history.push(generatePath(pages.comments.routePath!, { initialPage: event.parameters[0] }));
            return true;
        } else {
            return false;
        }
    }, [history]));

    useCommand("post_comment", useCallback(() => {
        if (history.location.pathname === pages.comments.path) return false;
        history.push(pages.comments.path, { forceFocus: true });
        return true;
    }, [history]))


    useCommand("change_key", changeKey)
    useCommand("reset_keys", resetKeys)
}