import { createMuiTheme, ThemeOptions } from "@material-ui/core";

const defaultTheme = createMuiTheme({});

export const lightTheme: ThemeOptions = {
    mixins: {
        customScrollbar: {
            "&::-webkit-scrollbar": {
                backgroundColor: "transparent",
                width: 14
            },
            "&::-webkit-scrollbar-track": {
                backgroundColor: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
                backgroundColor: "rgba(0,0,0,0.5)",
                borderRadius: 7,
                border: "4px solid transparent",
                backgroundClip: "content-box",
            },
            "&::-webkit-scrollbar-thumb:hover": {
                border: "2px solid transparent",
            },
            "&::-webkit-scrollbar-button": {
                display: "none"
            },
            scrollbarWidth: "thin",
            scrollbarColor: "rgba(0,0,0,0.5) transparent"
        }
    },
    props: {
        MuiButton: {
            variant: "contained",
            color: "secondary"
        },
        MuiButtonGroup: {
            variant: "contained",
            color: "secondary"
        },
        MuiTextField: {
            variant: "outlined"
        },
        MuiTooltip: { placement: "right", arrow: true },
    },
    overrides: {
        MuiListItemIcon: {
            root: {
                minWidth: "unset",
                paddingRight: defaultTheme.spacing(2)
            }
        },
        MuiTooltip: {
            tooltip: {
                backgroundColor: defaultTheme.palette.common.white,
                color: 'rgba(0, 0, 0, 0.87)',
                boxShadow: defaultTheme.shadows[1],
                fontSize: 12,
            },
        },
    },
    palette: {
        primary: {
            main: "#dc3030",
        },
        secondary: {
            main: "#4db6ac"
        },
        type: "light"
    },
    typography: {
        h5: { fontWeight: "bold" }
    }
};

export const darkTheme: ThemeOptions = { ...lightTheme, palette: { ...lightTheme.palette, type: "dark" } }