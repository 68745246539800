import React, { useState, useContext } from "react"
import Card from "../containers/Card"
import { Typography, makeStyles, Box, TextField, Button } from "@material-ui/core"
import { AuthContext } from "../components/auth/AuthContextProvider"
import Settings from "../data/Settings"
import { fetchEndpoint } from "../util/network"
import { useHistory } from "react-router-dom"
import pages from "../data/pages"
import { subscribeUser, unsubscribeUser } from "../service/subscribe"



const useStyles = makeStyles(theme => ({
    wrapper: {
        display: "flex",
        justifyContent: "center"
    },
    contianer: {
        flex: "0.3 1 30em"
    },
    formWrapper: {
        display: "flex",
        justifyContent: "center"
    },
    formContainer: {
        display: "flex",
        flexDirection: "column",
        flex: "0.8 1 20em"
    },
    form: {
    }
}))


export async function getLoginToken(endpoint: string, username: string, password: string) {
    const headers = new Headers();
    headers.append('Content-Type', 'application/json')
    let response;
    try {
        response = await fetch(endpoint, {
            method: 'POST', headers,
            body: JSON.stringify({ username, password })
        });
    } catch (e) {
        throw new Error("Login network error.")
    }
    return response;
}

export async function logout(updateAuthContext: () => void, tellServer = true) {
    localStorage.removeItem(Settings.LocalStorage.Comments)
    if (tellServer) {
        await fetchEndpoint(Settings.Api.Logout, { method: "post" });
    }
    await unsubscribeUser(false);
    localStorage.removeItem(Settings.LocalStorage.ServerFcmToken)
    localStorage.removeItem(Settings.LocalStorage.AuthKey);
    updateAuthContext();
}

export default () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [statusMessage, setStatusMessage] = useState<string | undefined>();

    const [authInfo, updateAuthContext] = useContext(AuthContext);

    const classes = useStyles();

    const history = useHistory();

    return <Card>
        <Typography variant="h4" align="center">Login</Typography>
        <div className={classes.formWrapper}>
            <div className={classes.formContainer}>
                <form className={classes.form} onSubmit={async (e) => {
                    e.preventDefault();
                    setStatusMessage("Submitting...")
                    try {
                        const res = await getLoginToken(Settings.Api.Login, username, password);
                        if (res.status !== 200) {
                            setStatusMessage(await res.text())
                        } else {
                            localStorage.setItem(Settings.LocalStorage.AuthKey, await res.text());
                            const authInfo = updateAuthContext()
                            if (authInfo?.verified) {
                                const target = pages.comments.path
                                if (history.location.pathname !== target) {
                                    history.push(target)
                                }
                                await subscribeUser();
                            } else {
                                setStatusMessage("Login response successful")
                            }
                        }
                    } catch (e) {
                        setStatusMessage(e.message)
                    }
                }}>
                    <Box p={1}>
                        <TextField label="Username" variant="filled" value={username} required fullWidth
                            onChange={(e) => setUsername(e.target.value)} />
                    </Box>
                    <Box p={1}>
                        <TextField label="Password" variant="filled" value={password} type="password" required fullWidth
                            onChange={(e) => setPassword(e.target.value)} />
                    </Box>
                    <Box p={1} display="inline-block"><Button type="submit" color="primary">Login</Button></Box>
                    {authInfo && <Box p={1} display="inline-block">
                        <Button onClick={() => logout(updateAuthContext)}>Logout</Button></Box>}
                </form>
                <Box>
                    {statusMessage}
                </Box>
                {authInfo &&
                    <Box>Currently logged in as {authInfo?.username}</Box>}
            </div>
        </div>
    </Card>
}