import React, { useContext } from "react"
import { Comment } from "./Comment"
import ConfirmationModal from "../../components/ConfirmationModal";
import { makeStyles, Typography } from "@material-ui/core";
import { codeBlockStyling } from "../../components/MarkdownDisplay";
import { CommentsPageContext } from "../../pages/Comments";

interface Props {
    comment: Comment;
    onClose: () => void;
}

const useClasses = makeStyles(theme => ({
    pre: { ...codeBlockStyling(theme), margin: 0 }
}))

export default ({ comment, onClose }: Props) => {
    const context = useContext(CommentsPageContext);

    const classes = useClasses();
    return <ConfirmationModal onClose={confirmed => {
        if (confirmed) {
            context.appendToEditor(comment.body)
        }
        onClose()
    }} confirm="Copy to Editor" deny="Close"
        title={<>Source for comment posted by <b>{comment.username}</b></>}>
        <Typography variant="body1" component="pre" className={classes.pre}>
            <code>{comment.body}</code>
        </Typography>
    </ConfirmationModal>
}