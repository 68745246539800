import React from "react"
import Card from "../containers/Card"
import { Typography, Link } from "@material-ui/core"
import pages from "../data/pages"
import { Link as RouterLink } from "react-router-dom"



export default () => {
    return <Card>
        <Typography variant="h5" align="center">Index Page</Typography>
        <Typography variant="h6">
            <Link component={RouterLink} to={pages.login.path}>Login here</Link> or click the profile icon
        </Typography>
        <Typography variant="h6">This page is temporary don't worry</Typography>
    </Card>
}