import { fetchEndpoint } from "../util/network";
import Settings from "../data/Settings";

const serviceWorker = "/messaging-sw.js";
const scope = "/push-scope"

const vapid = new Uint8Array(
    [4, 59, 49, 21, 45, 228, 114, 32, 40, 97,
        56, 248, 135, 192, 4, 73, 53, 246, 131, 60,
        170, 84, 208, 158, 196, 37, 143, 156, 244, 220,
        232, 154, 231, 34, 40, 19, 240, 177, 74, 101,
        23, 146, 249, 107, 104, 137, 68, 166, 239, 45,
        150, 93, 85, 70, 219, 58, 240, 190, 143, 178,
        75, 206, 226, 11, 117]);

async function sendTokenToServer(sub: PushSubscription) {
    const token = JSON.stringify(sub.toJSON());
    await fetchEndpoint(Settings.Api.Notifications.Token, { method: "post", body: { token } })
    setTokenSentToServer(token)
    return undefined;
}

async function deleteTokenFromServer() {
    setTokenSentToServer()
    await fetchEndpoint(Settings.Api.Notifications.Token, { method: "delete" })
}

function setTokenSentToServer(token?: string) {
    if (token) {
        localStorage.setItem(Settings.LocalStorage.ServerFcmToken, token)
    } else {
        localStorage.removeItem(Settings.LocalStorage.ServerFcmToken);
    }
}

async function getSubscription(subscribe: boolean = false) {
    let registration = await navigator.serviceWorker.getRegistration(scope);
    if (!registration || !registration.scope.endsWith(scope)) {
        // TODO can remove this eventually
        const fbScope = "/firebase-cloud-messaging-push-scope";
        const oldReg = await navigator.serviceWorker.getRegistration(fbScope);
        if (oldReg && oldReg.scope.endsWith(fbScope)) await oldReg.unregister();
        registration = await navigator.serviceWorker.register(serviceWorker, { scope });
    }
    if (!registration) {
        console.error("Failed to get notification service worker");
        return
    }
    const current = await registration.pushManager.getSubscription();
    if (current) {
        console.log("Returning already found subscription")
        return current;
    } else if (subscribe) {
        return await registration.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: vapid
        });
    }
}

export async function subscribeUser(): Promise<string | undefined> {
    try {
        const sub = await getSubscription(true);
        if (sub) {
            return await sendTokenToServer(sub);
        } else {
            setTokenSentToServer()
            return "Missing permissions";
        }
    } catch (err) {
        console.log('An error occurred while retrieving token. ', err);
        setTokenSentToServer()
        return "An error occurred while retrieving token";
    }
}

export async function unsubscribeUser(updateServer = true) {
    const token = localStorage.getItem(Settings.LocalStorage.ServerFcmToken);
    if (token) {
        const res = await getSubscription();
        if (res) {
            res.unsubscribe();
            if (updateServer) {
                deleteTokenFromServer();
            }
            return "Token deleted";
        } else {
            return "Token not deleted";
        }
    } else {
        return "No token found to delete"
    }
}