import React, { ReactNode } from 'react'
import { makeStyles } from '@material-ui/core'
import { codeBlockStyling } from './MarkdownDisplay';

interface Props {
    children: ReactNode
}

const useStyles = makeStyles(theme => ({
    root: { ...codeBlockStyling(theme) }
}))

export default ({ children }: Props) => {
    const classes = useStyles();

    return <code className={classes.root}>{children}</code>
}