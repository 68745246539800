import React, { useContext, useState, useRef, useCallback } from "react"
import ChatIcon from '@material-ui/icons/Chat';
import { Box, Fab, useTheme, Paper, Grow } from "@material-ui/core";
import { LayoutContext } from "../Layout";
import ChatBox from "./ChatBox";
import { useOnClickOutside } from "../../hooks/useOnClickOutside";
import useCommand from "../../hooks/commands/useCommand";

// This only handles the occasion where the chat is opened from the Fab icon in the corner
// For the actual chat implementation see ChatBox.tsx

interface Props {
    hide?: boolean;
}

export default ({ hide }: Props) => {
    const shrink = useContext(LayoutContext).shrink;

    const theme = useTheme();

    const [open, setOpen] = useState(false);


    const popupHeight = theme.spacing(50);

    const ref = useRef<HTMLDivElement>(null);

    useOnClickOutside(ref, useCallback(() => {
        setOpen(false)
    }, []));

    useCommand("toggle_chat", useCallback(() => {
        setOpen(open => !open);
        return true;
    }, []));
    useCommand("close", useCallback(() => {
        if (open) { setOpen(false); return true; }
        return false;
    }, [open]))

    const popupWidth = shrink === 2 ? "100vw" : shrink === 1 ? "600px" : "800px"

    return <div ref={ref} style={{
        display: hide ? "none" : "block",
        zIndex: 1,
        position: "fixed", right: 0, bottom: 0,
        transform: open ? `translate(0,${-popupHeight}px)` : undefined,
        transition: theme.transitions.create("transform")
    }}>
        <Box p={2}>
            <Fab color="primary" size={shrink === 2 ? "small" : "medium"} onClick={() => setOpen(o => !o)}>
                <ChatIcon />
            </Fab>
        </Box>
        <Grow in={open} mountOnEnter>
            <Paper style={{
                position: "absolute", right: 0, height: popupHeight,
                borderBottom: "none", borderRight: "none",
                borderBottomRightRadius: 0,
                borderBottomLeftRadius: 0,
                borderTopRightRadius: 0,
                borderTopLeftRadius: shrink === 2 ? 0 : undefined,
                width: popupWidth
            }} variant="outlined">
                <ChatBox fullPageButton autoFocus={open} disableRoutes />
            </Paper>
        </Grow>
    </div>
}