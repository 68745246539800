import { useEffect } from "react";

const handlers = new Set<(event: KeyboardEvent) => boolean>();

document.addEventListener("keydown", e => {
    for (const x of handlers) {
        if (x(e)) break;
    }
});

export default (handler: (e: KeyboardEvent) => boolean) => {
    useEffect(() => {
        handlers.add(handler);
        return () => { handlers.delete(handler); }
    }, [handler]);
}