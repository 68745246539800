import MarkdownIt from "markdown-it";
import RedditLinks from "./RedditLinks";
import Emotes from "./Emotes";
import LinkTarget from "./LinkTarget";

// Creates a shared MarkdownIt instance

const Markdown = new MarkdownIt({ linkify: true });
Markdown.disable("image")
LinkTarget(Markdown); // Add target=_blank to all links
RedditLinks(Markdown); // Adds automatic reddit linking (r/test => https://reddit.com/r/test)
Emotes(Markdown); // Adds emote plugin


export default Markdown;