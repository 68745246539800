import React from 'react';
import './App.css';
import { CssBaseline } from '@material-ui/core'
import Routes from './Routes';
import { BrowserRouter } from 'react-router-dom';
import AuthContextProvider from './components/auth/AuthContextProvider';
import CustomThemeProvider from './components/CustomThemeProvider';
import ServiceWorkerHandler from './service/ServiceWorkerHandler';
import { darkTheme } from './data/Themes';

// This file sets up the app layout and all the wrapper components/contexts
// Shouldn't do anything significant

export default () => {
    return <CustomThemeProvider theme={darkTheme}>
        <CssBaseline />
        <AuthContextProvider>
            <ServiceWorkerHandler>
                <BrowserRouter>
                    <Routes />
                </BrowserRouter>
            </ServiceWorkerHandler>
        </AuthContextProvider>
    </CustomThemeProvider>
};
