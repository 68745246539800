import React, { ReactNode, useContext, createContext, createRef, RefObject, useRef, useMemo } from "react"
import { AppBar, IconButton, Toolbar, Typography, useTheme, Tabs, Tab, createStyles, makeStyles, Avatar, Link, Box } from "@material-ui/core"
import { matchPath, useLocation, Link as RouterLink } from "react-router-dom"
import pages from "../data/pages"
import { AuthContext } from "../components/auth/AuthContextProvider";
import useMediaQuery from "../hooks/useMediaQuery";
import ChatContextProvider from "./chat/ChatContextProvider";
import UserAvatar from "./UserAvatar";
import CommandPalette from "./CommandPalette";

interface Props {
    children: ReactNode;
    disableChatWidget?: boolean;
}

const useStyles = makeStyles(theme => createStyles({
    fullHeight: {
        ...theme.mixins.toolbar,
    },
    logo: {
        overflow: "visible"
    },
    scrollableContent: {
        ...theme.mixins.customScrollbar,
        "flex": 1,
        overflowY: "auto",
    }
}));

export const LayoutContext = createContext<{ shrink: number, scrollRef: RefObject<HTMLElement> }>({
    shrink: 0, scrollRef: createRef()
});

export default ({ children, disableChatWidget }: Props) => {
    const tabs = useMemo(() => Object.values(pages).filter(e => e.tab), [])
    const theme = useTheme();
    const appBarBg = theme.palette.type === "dark" ? "default" : "primary"
    const appBarColor = theme.palette.type === "dark" ? "primary" : "inherit"
    const classes = useStyles();

    const path = useLocation().pathname;
    const [authInfo,] = useContext(AuthContext)

    const shrink1 = !useMediaQuery(`(min-width: ${theme.breakpoints.values.md}px)`);
    const shrink2 = !useMediaQuery(`(min-width: ${theme.breakpoints.values.sm}px)`);
    const shrink = shrink2 ? 2 : shrink1 ? 1 : 0;

    const filteredTabs = tabs.filter(t => {
        if (shrink >= 1 && t.label === "Home") {
            // don't need to show home button on mobile since there's the icon
            return false;
        }
        return (!t.verified || authInfo?.verified) && (!t.admin || authInfo?.admin)
    });

    let currentTab: number | false = false;
    filteredTabs.forEach((tab, i) => {
        if (matchPath(path, { path: tab.path, exact: tab.tab?.exact }) !== null) {
            currentTab = i;
        }
    })

    const logo = shrink1 ? <Box px={1}>
        <Typography variant="h5">
            <Link component={RouterLink} to="/" color={appBarColor}>
                <IconButton color="inherit" size={shrink2 ? "small" : "medium"}>
                    <Avatar src="/img/small.svg" alt="J" className={classes.logo} />
                </IconButton>
            </Link>
        </Typography>
    </Box>
        : <Box px={3}>
            <Typography variant="h5">
                <Link component={RouterLink} to="/" color={appBarColor}>Jumprocks</Link>
            </Typography>
        </Box>

    const scrollRef = useRef<HTMLDivElement>(null);


    return <div style={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <CommandPalette />
        <AppBar position="static" color={appBarBg} elevation={4}>
            <Toolbar disableGutters>
                {/* <IconButton edge="start" aria-label="menu" color="inherit">
                    <MenuIcon />
                </IconButton> */}
                {logo}
                <Tabs value={currentTab} className={classes.fullHeight} variant="scrollable">
                    {filteredTabs.map(tab => <Tab
                        key={tab.label}
                        className={classes.fullHeight}
                        label={tab.label}
                        component={RouterLink}
                        to={tab.path}
                    />)}
                </Tabs>
                <div style={{ flex: 1 }}></div>
                <Box px={shrink1 ? 1 : 2}>
                    <Link component={RouterLink} to={authInfo ?
                        pages.profile.path :
                        pages.login.path} >
                        <IconButton color="inherit" size={shrink2 ? "small" : "medium"}>
                            <UserAvatar user={authInfo?.username} hasImage={authInfo?.hasImage ?? false} />
                        </IconButton>
                    </Link>
                </Box>
            </Toolbar>
        </AppBar>
        <div className={classes.scrollableContent} ref={scrollRef}>
            <LayoutContext.Provider value={{ shrink, scrollRef }}>
                <ChatContextProvider disableChatWidget={disableChatWidget}>
                    {children}
                </ChatContextProvider>
            </LayoutContext.Provider>
        </div>
    </div>
}