import React from "react"
import { makeStyles, Theme } from "@material-ui/core";
import { emoteBaseStyle } from "../util/markdown/Emotes";
import { blueGrey } from "@material-ui/core/colors";
import Markdown from "../util/markdown/Markdown";

interface Props {
    text: string;
}

export const codeBlockStyling = (theme: Theme) => ({
    borderRadius: "4px",
    padding: "4px",
    overflowX: "auto",
    backgroundColor: theme.palette.augmentColor(blueGrey).dark,
    color: theme.palette.grey[100]
} as const)

const useClasses = makeStyles(theme => ({
    root: {
        // マージンが大嫌い
        "& *": {
            margin: 0
        },
        // makes links look like Mui Links
        "& a": {
            color: theme.palette.primary.main,
            textDecoration: "none",
            "&:hover": {
                textDecoration: "underline"
            }
        },
        "& p > code, pre": codeBlockStyling(theme),
        "& .emote": emoteBaseStyle,
        "& p:not(:last-child)": {
            marginBottom: theme.spacing(1)
        },
        "& hr": {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1)
        }
    }
}))

export default (({ text }: Props) => {
    const classes = useClasses();
    const render = Markdown.render(text, {});
    return <div className={classes.root} dangerouslySetInnerHTML={{ __html: render }} />
})