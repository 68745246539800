import MarkdownIt from "markdown-it";
import Token from "markdown-it/lib/token";
import StateCore from "markdown-it/lib/rules_core/state_core";
import Emotes from "../../data/Emotes";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
import EmoteAtlas from "../../img/AtlasV7.png"


export const emoteBaseStyle: CSSProperties = {
    backgroundImage: `url(${EmoteAtlas})`,
    backgroundRepeat: "no-repeat",
    display: "inline-block",
    verticalAlign: "middle"
}

const emoteRegex = new RegExp("\\b(" + Object.keys(Emotes.emotes).concat(Object.keys(Emotes.aliases)).join("|") + ")\\b", "g")

const plugin = (state: StateCore) => {
    state.tokens.forEach(token => {
        if (token.type === 'inline') {
            let autoLink = 0;
            const newChildren: Token[] = []
            token.children.forEach(token => {
                if ((token.type === 'link_open' || token.type === 'link_close') && token.info === 'auto') {
                    autoLink += token.nesting;
                }
                if (token.type === 'text' && autoLink === 0) {
                    const content = token.content;
                    // Reset RegExp object
                    let preMatch = emoteRegex.lastIndex = 0
                    let match = emoteRegex.exec(content)
                    if (!match) {
                        newChildren.push(token)
                    } else {
                        while (match) {
                            if (match.index !== 0) {
                                const newToken: Token = new state.Token("text", "", 0)
                                newToken.content = content.substring(preMatch, match.index);
                                newChildren.push(newToken)
                            }
                            const newToken: Token = new state.Token("emote", "", 0)
                            newToken.content = match[0];
                            newChildren.push(newToken)
                            preMatch = emoteRegex.lastIndex
                            match = emoteRegex.exec(content)
                        }
                        if (preMatch < content.length) {
                            const newToken: Token = new state.Token("text", "", 0)
                            newToken.content = content.substring(preMatch, content.length);
                            newChildren.push(newToken)
                        }
                    }
                } else {
                    newChildren.push(token)
                }
            })
            token.children = newChildren;
        }
    })
}

export const getStyles = (emote: string) => {
    if (emote in Emotes.aliases) {
        emote = Emotes.aliases[emote as keyof typeof Emotes.aliases];
    }
    if (emote in Emotes.emotes) {
        const layout = Emotes.emotes[emote as keyof typeof Emotes.emotes]
        return {
            backgroundPosition: `${-layout[0]}px ${-layout[1]}px`,
            width: layout[2] + "px",
            height: layout[3] + "px",
            zoom: layout[4] ? 1 / layout[4] : undefined,
            imageRendering: layout[5] ? "pixelated" : undefined
        } as const
    } else {
        throw new Error(`Could not find emote ${emote}`);
    }
}

// Enables emotes on a MarkdownIt object
export default (md: MarkdownIt) => {
    md.renderer.rules["emote"] = (tokens: Token[], index: number) => {
        const css = getStyles(tokens[index].content);
        const style = `background-position:${css.backgroundPosition};width:${css.width};` +
            `height:${css.height};${css.zoom ? `zoom:${css.zoom};` : ""}` +
            `${css.imageRendering ? `image-rendering:${css.imageRendering}` : ""}`
        return `<span class="emote" style="${style}" title="${tokens[index].content}"></span>`
    };
    md.core.ruler.push("emote", plugin)
}