import MarkdownIt from "markdown-it";

// Adds target=_blank to all link tags
export default (md: MarkdownIt) => {
    // Current renderer for links, normally this will be undefined, so we make a simple one
    const oldRenderer = md.renderer.rules.link_open ??
        ((tokens, idx, options, _, self) => self.renderToken(tokens, idx, options))

    md.renderer.rules.link_open = (tokens, idx, options, env, self) => {
        tokens[idx].attrPush(['target', '_blank']);
        tokens[idx].attrPush(['rel', 'noopener']);
        return oldRenderer(tokens, idx, options, env, self);
    };

}