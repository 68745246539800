export { default as atlas } from "../img/AtlasV7.png"

const version = 7

// For when I redo Emote API
interface EmoteInformation {
    x: number;
    y: number;
    width: number;
    height: number;
    scale?: number;
    pixelated?: boolean;
}

const emotes = applyEmoteType({
    Eggplant: [25, 0, 25, 28],
    KappaPride: [50, 0, 25, 28],
    KappaClaus: [75, 0, 25, 28],
    KappaRoss: [100, 0, 25, 28],
    KappaXL: [198, 78, 94, 128],
    Kappa: [0, 0, 25, 28],
    Clover: [125, 0, 23, 28],
    Klappa: [148, 0, 31, 28],
    GoldStar: [179, 0, 29, 28],
    BabyRage: [208, 0, 28, 28],
    NotLikeThis: [236, 0, 28, 28],
    FeelsAmazingMan: [264, 0, 28, 28],
    AngelThump: [292, 0, 84, 28],

    BibleThump: [0, 28, 36, 30],
    PJSalt: [36, 28, 36, 30],
    FeelsBadMan: [72, 28, 30, 30],
    FeelsGoodMan: [102, 28, 30, 30],

    ThaisonHD: [0, 58, 198, 256, 8],
    ThaisonXL: [0, 58, 198, 256],

    Heart: [198, 58, 12, 12, 0.42857, 1],
    SSSsss: [198, 70, 8, 8, 0.2857143, 1],

    Rick: [0, 314, 113, 128, 2],
    OOHWEE: [113, 314, 58, 128, 1.5],
    PickleRick: [171, 314, 97, 128, 2],
    Squanch: [268, 314, 104, 128, 2],
    SMWYG: [372, 314, 89, 128, 1.5],
    Jerry: [292, 186, 91, 128, 2],
    Morty: [383, 186, 121, 128, 2],
    KrombopulosMichael: [292, 58, 112, 128, 2],
    Snuffles: [404, 58, 87, 128, 2],
})

type Emote = readonly [number, number, number, number] |
    readonly [number, number, number, number, number] |
    readonly [number, number, number, number, number, number]

function applyEmoteType<T extends { [key in string]: Emote }>(e: T) { return e; }

export type EmoteName = keyof typeof emotes

const aliases = applyAliasType({
    Snowball: "Snuffles",
    HereIGoKillingAgain: "KrombopulosMichael",
    Krombopulos: "KrombopulosMichael",
    ShowMeWhatYouGot: "SMWYG",
    MrPoopyButthole: "OOHWEE",
    Squanchy: "Squanch"
})
function applyAliasType<T extends { [key in string]: EmoteName }>(e: T) { return e; }

export default {
    version,
    width: 512,
    height: 512,
    emotes,
    aliases
} as const