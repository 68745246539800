import React from "react"
import { Box, CircularProgress, Typography, LinearProgress } from "@material-ui/core"
import Card from "./Card"

interface Props {
    circular?: boolean;
    cached?: boolean;
}

export default ({ circular, cached }: Props) => {
    const message = "Loading..." + (cached ? " (serving from cache)" : "")
    return <Card>
        {circular ?
            <Box display="flex" alignItems="center" flexDirection="column">
                <Box pb={1}><Typography variant="h6" align="center">{message}</Typography></Box>
                <CircularProgress />
            </Box> :
            <Box display="flex" alignItems="stretch" flexDirection="column">
                <Box pb={1}><Typography variant="h6" align="center">{message}</Typography></Box>
                <LinearProgress />
            </Box>}
    </Card>
}