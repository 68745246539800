import React, { ReactNode } from "react";
import ChatWidget from "./ChatWidget";

export const ChatContext = React.createContext<undefined>(undefined)

interface Props {
    children: ReactNode;
    disableChatWidget?: boolean;
}

export default ({ children, disableChatWidget }: Props) => {
    return <ChatContext.Provider value={undefined}>
        <ChatWidget hide={disableChatWidget} />
        {children}
    </ChatContext.Provider>
}