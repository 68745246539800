import { useEffect } from "react";

type Handler = [React.RefObject<HTMLElement>, (e: MouseEvent) => void];
const handlers = new Set<Handler>();

document.addEventListener("mousedown", (e) => {
    if (e.button === 0) {
        handlers.forEach(([ref, clickHandler]) => {
            if (!ref.current || !ref.current.contains(e.target as Node)) {
                clickHandler(e);
            }
        });
    }
});

/**
 * Triggers handler each time a user presses left mouse down on an element that is not a child of ref.current
 */
export function useOnClickOutside(ref: React.RefObject<HTMLElement>, clickHandler: (e: MouseEvent) => void) {
    useEffect(() => {
        const tuple: Handler = [ref, clickHandler]
        handlers.add(tuple);
        return () => { handlers.delete(tuple); }
    }, [ref, clickHandler]);
}