import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../components/auth/AuthContextProvider'
import Member from '../containers/Member';
import pages from '../data/pages';

export default () => {
    const [authInfo] = useContext(AuthContext);
    if (!authInfo) return <Redirect to={pages.login.path} />
    return <Member username={authInfo.username} />
}