import { useState, useEffect } from "react";
import { fetchCallback, FetchConfig } from "../../util/network";

type EndpointConfig = {
    forceSend?: number;
    onError?: (e: Error) => void;
    localStorageKey?: string;
} & FetchConfig

function useEndpoint<Data>(endpoint: string, config?: EndpointConfig):
    [Data | undefined, boolean]
function useEndpoint<Data, ProcessedData>(endpoint: string, process: (data: Data) => ProcessedData, config: EndpointConfig):
    [ProcessedData | undefined, boolean]

function useEndpoint<Data, ProcessedData>(endpoint: string,
    param2?: ((data: Data) => ProcessedData) | EndpointConfig,
    param3?: EndpointConfig): [ProcessedData | Data | undefined, boolean] {

    const process = typeof param2 === "function" ? param2 : undefined;
    let config: EndpointConfig | undefined = typeof param2 === "function" ? param3 : param2;

    const { method, body, authInfo, requireAuth, forceSend, onError, localStorageKey } = config ?? {};

    const [data, setData] = useState<[ProcessedData | Data | undefined, boolean]>(() => {
        if (!localStorageKey) return [undefined, true];
        const ls = localStorage.getItem(localStorageKey);
        return [ls ? JSON.parse(ls) : undefined, true]
    });

    useEffect(() => {
        console.log("network request")
        return fetchCallback<Data>(endpoint, data => {
            if (data) {
                const processedData = process?.(data) ?? data;
                if (localStorageKey) {
                    localStorage.setItem(localStorageKey, JSON.stringify(processedData));
                }
                setData(_ => [processedData, false]);
            }
        }, onError, { method, body, authInfo, requireAuth })
    }, [endpoint, localStorageKey, onError, process, forceSend, method, body, authInfo, requireAuth])

    return data;
}

export default useEndpoint