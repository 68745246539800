import React, { useState, useContext } from "react"
import { makeStyles, Typography, createStyles, Link, Box, IconButton, Menu, MenuItem } from "@material-ui/core"
import Divider from "@material-ui/core/Divider"
import toRecent from "../../util/toRecent";
import Spacer from "../../components/auth/Spacer";
import Card from "../Card";
import { Link as RouterLink } from "react-router-dom"
import MoreVertIcon from "@material-ui/icons/MoreVert"
import ConfirmationModal from "../../components/ConfirmationModal";
// import { default as MarkdownDisplay } from "../../components/CustomMarkdownDisplay";
import MarkdownDisplay from "../../components/MarkdownDisplay";
import { LayoutContext } from "../Layout";
import ViewSourceModal from "./ViewSourceModal";
import Markdown from "../../util/markdown/Markdown";
import UserAvatar from "../UserAvatar";


export interface Comment {
    username: string;
    body: string;
    createdOn: number;
    _id?: string;
    hasImage?: boolean;
}

interface Props {
    comment: Comment;
    canDelete?: boolean;
    deleteComment?: (_id: string) => void
}

const useStyles = makeStyles(theme => createStyles({
    author: {
        color: theme.palette.info.light,
        fontWeight: "bold"
    },
    time: {
        fontStyle: "italic"
    },
    bodyWrapper: {
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        flex: 1,
        display: "flex",
        alignItems: "center",
        position: "relative"
    },
    body: {
        overflowWrap: "anywhere",
        overflow: "hidden",
        width: "100%"
        //whiteSpace: "pre-wrap"
    },
    footer: {
        display: "flex",
        alignItems: "center",
        paddingTop: theme.spacing(1),
        paddingBottom: theme.spacing(1),
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2)
    },
    footerShrink: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(1)
    }
}));

export default ({ comment, canDelete, deleteComment }: Props) => {
    const classes = useStyles();

    const [deleteModal, setDeleteModal] = useState<boolean>(false);
    const [viewSourceModal, setViewSourceModal] = useState<boolean>(false);

    // If ShrinkContext is >= 1 we switch layout
    const shrink = useContext(LayoutContext).shrink >= 1

    const openPopupMenu = <IconButton size='small' onClick={e => setPopupAnchor(e.currentTarget)}><MoreVertIcon fontSize="small" /></IconButton>

    // TODO make avatar here lazy/make component for it
    const extraInfo = <div className={shrink ? classes.footerShrink : classes.footer}>
        {shrink && <Box pr={2}>
            <Link component={RouterLink} to={`/members/${comment.username}`} style={{ textDecoration: "none" }} >
                <UserAvatar user={comment.username} />
            </Link>
        </Box>}
        <Typography variant="body1" className={classes.author}>
            <Link component={RouterLink} to={`/members/${comment.username}`} style={{ color: "inherit" }}>
                {comment.username}
            </Link>
        </Typography>
        <Spacer />
        <Typography variant="body1" className={classes.time}>{toRecent(comment.createdOn)}</Typography>
        {shrink && <Box pl={1}>{openPopupMenu}</Box>}
    </div>

    const [popupAnchor, setPopupAnchor] = useState<HTMLElement | undefined>()

    const closePopup = () => {
        setPopupAnchor(undefined);
    }

    const popupMenu = <Menu open={Boolean(popupAnchor)} anchorEl={popupAnchor} onClose={closePopup}>
        <MenuItem onClick={() => { setViewSourceModal(true); setPopupAnchor(undefined) }}>View Source</MenuItem>
        <MenuItem onClick={() => {
            navigator.clipboard.writeText(comment.body);
            setPopupAnchor(undefined)
        }}>Copy Source</MenuItem>
        <MenuItem onClick={() => {
            const content = Markdown.parse(comment.body, {});
            console.log(content)
            const out: string[] = []
            content.forEach(t => {
                if (t.children) {
                    t.children.forEach(t => {
                        if (t.type === "text" || t.type === "emote") {
                            out.push(t.content)
                        }
                    })
                    out.push("\n")
                } else if (t.type === "fence") {
                    out.push(t.content)
                }
            })
            navigator.clipboard.writeText(out.join('').trimEnd());
            setPopupAnchor(undefined);
        }}>Copy Text</MenuItem>
        {canDelete && deleteComment && <MenuItem
            onClick={() => { setPopupAnchor(undefined); setDeleteModal(true) }}>
            Delete</MenuItem>}
    </Menu>

    const body = <div className={classes.bodyWrapper}>
        <Typography variant="body1" className={classes.body} component="div">
            <MarkdownDisplay text={comment.body} />
        </Typography>
        {!shrink && <Box position="absolute" right={0} top={0}>
            {openPopupMenu}
        </Box>}
        {popupMenu}
        {viewSourceModal && <ViewSourceModal comment={comment} onClose={() => setViewSourceModal(false)} />}
        {deleteModal && <ConfirmationModal onClose={c => {
            if (c && deleteComment && comment._id) {
                deleteComment(comment._id);
            }
            setDeleteModal(false)
        }} confirm="Yes" deny="No"
            title={<>Are you sure you would like to delete comment posted by <b>{comment.username}</b></>}>
            <Typography variant="body1">This action cannot be undone (easily) (yet).</Typography>
        </ConfirmationModal>}
    </div>

    const top = shrink ? extraInfo : body;
    const bot = shrink ? body : extraInfo;

    return <Card noPadding username={shrink ? undefined : comment.username} lazy={true} hasImage={comment.hasImage}>
        {top}
        <Divider />
        {bot}
    </Card>
}