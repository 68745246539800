import { useCallback, useState } from "react";

export default <T extends string | undefined>(initial: T, key: string) => {
    const [state, _setState] = useState<string | T>(() => {
        const value = localStorage.getItem(key);
        if (!value) {
            return initial;
        } else {
            return value;
        }
    });
    const setState = useCallback((e: string | T) => {
        _setState(e);
        if (e === undefined) {
            localStorage.removeItem(key);
        } else {
            localStorage.setItem(key, e!);
        }
    }, [key])
    return [state, setState] as const;
}