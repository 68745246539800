import React from "react"
import Settings from "../data/Settings";
import Card from "../containers/Card";
import { useParams } from "react-router-dom";
import Member from "../containers/Member";
import Loading from "../containers/Loading";
import useEndpoint from "../hooks/network/useEndpoint";

type Member = {
    username: string;
    hasImage?: boolean;
}

const MemberIndex = () => {
    const [members] = useEndpoint<Member[]>(Settings.Api.User, { localStorageKey: Settings.LocalStorage.Members })
    return <>
        {members ? members.map(m =>
            <Card key={m.username} username={m.username} hasImage={m.hasImage} lazy={true}>{m.username}</Card>)
            : <Loading />}
    </>
}

export default () => {
    const { username } = useParams<{ username?: string }>();

    return username ? <Member username={username} /> : <MemberIndex />
}