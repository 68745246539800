import React from 'react'
import { TextFieldProps, TextField, makeStyles } from '@material-ui/core'

type Props = {
    TextFieldProps?: Partial<TextFieldProps>
}

const useClasses = makeStyles(theme => ({
    input: {
        background: theme.palette.background.paper
    }
}))

export default ({ TextFieldProps }: Props) => {
    const classes = useClasses();

    return <TextField {...TextFieldProps}
        inputProps={{ className: classes.input }} />
}