import React, { useRef, useState } from 'react'
import Card from '../../containers/Card'
import { Page } from '../../data/pages'
import { Button, Typography, TextField, Box, CircularProgress, Tooltip, useTheme, Divider, makeStyles } from '@material-ui/core'
import { wrapTooltip } from '../../util/wrapTooltip'
import Settings from '../../data/Settings'
import { fetchEndpoint } from '../../util/network'
import Monospace from '../../components/Monospace'
import { ListResponse, CharacterResponse } from '../../data/JikanApi'

interface Props {
    page: Page
}

interface Character { name: string; image: string; url: string; }

interface VoiceActor {
    character: Character
    name: string
}

interface MalCache {
    lists: {
        [key in string]?: number[]
    },
    anime: { [key in number]: Anime }
}

interface Anime { title: string; mal_id: number; voice_actors?: VoiceActor[] }
interface Comparison {
    source: VoiceActor;
    comparisons: {
        plays: Character
        from: Anime[]
    }[]
}
interface VAComparison {
    anime: Anime
    comparison: Comparison[]
}

function sleep(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

async function getList(user: string, animes: { [key in number]: Anime }, pageCallback?: (page: number) => void) {
    const anime: Anime[] = []

    let page = 1;

    while (true) {
        pageCallback?.(page)
        const result = await fetchEndpoint(`${jikan}/user/${user}/animelist/all/${page++}`)
        const parsed = result ? JSON.parse(result) as ListResponse : undefined;
        if (!parsed || parsed.anime.length === 0) break;
        parsed.anime.forEach(a => { anime.push({ title: a.title, mal_id: a.mal_id, voice_actors: animes[a.mal_id]?.voice_actors }) })
        if (parsed.anime.length < 300) break;
        await sleep(1000);
    }
    return anime;
}

async function downloadVAData(ids: number[], callback?: (progress: number, va: VoiceActor[]) => void) {
    let i = 0;
    for (const id of ids) {
        const result = await fetchEndpoint(`${jikan}/anime/${id}/characters_staff`)
        const parsed = result ? JSON.parse(result) as CharacterResponse : undefined;
        if (parsed) {
            const o: VoiceActor[] = []
            parsed.characters.forEach(e => {
                e.voice_actors.filter(e => e.language === "Japanese").forEach(va => {
                    console.log(va.name, "voices", e.name)
                    o.push({ character: { image: e.image_url, name: e.name, url: e.url }, name: va.name })
                });
            })
            callback?.(i, o)
        }
        i++;
        if (i < ids.length) {
            await sleep(2100);
        }
    }
}

const useStyles = makeStyles(theme => ({
    comparison: {
        display: "flex",
        alignItems: "center"
    },
    divider: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    }
}))

const jikan = Settings.Api.Jikan

export default ({ page }: Props) => {

    const usage = useRef<number>()

    const [storage, setStorage] = useState<MalCache>(() => {
        const ls = localStorage.getItem(Settings.LocalStorage.MALCache)
        usage.current = ls?.length ?? 0;
        return (ls ? JSON.parse(ls) : undefined) ?? { anime: {}, lists: {} }
    })

    const save = (newCache: Partial<MalCache>) => {
        setStorage(oldCache => {
            const saveCache = {
                lists: { ...oldCache.lists, ...newCache.lists },
                anime: { ...oldCache.anime, ...newCache.anime }
            }
            localStorage.setItem(Settings.LocalStorage.MALCache, JSON.stringify(saveCache));
            return saveCache;
        })
    }

    let targetUser: string | undefined;


    if (storage.lists) {
        const keys = Object.keys(storage.lists)
        if (keys.length === 1) {
            targetUser = keys[0]
        }
    }


    const currentList: number[] = targetUser ? (storage?.lists?.[targetUser] ?? []) : []

    const malIdRef = useRef<HTMLInputElement>(null);
    const animeIdRef = useRef<HTMLInputElement>(null);

    const [loadingList, setLoadingList] = useState<number | undefined>();
    const [loadingVA, setLoadingVA] = useState<{ missingVA: number[], i: number } | undefined>();
    const [vaComparison, setVaComparison] = useState<VAComparison | undefined>();

    const missingVA = currentList.filter(e => !(storage.anime[e]?.voice_actors))

    const theme = useTheme();
    const secondary = theme.palette.text.secondary;

    const classes = useStyles();

    const renderVaComparison = (vaComparison: VAComparison | undefined) => {
        if (!vaComparison) return null;
        const len = vaComparison.comparison.length;
        return vaComparison.comparison.map((e, i) => {
            const o = <div key={i} className={classes.comparison}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <div>{e.source.name}</div>
                    <img loading="lazy" src={e.source.character.image} alt={e.source.character.name} title={e.source.character.name}
                        width={135} height={210} />
                </div>
                <div style={{ paddingLeft: 10 }}>
                    {e.comparisons.map((t, i) => <div key={i}><Tooltip
                        title={<img src={t.plays.image} alt={e.source.character.name} loading="lazy" width={225} height={350} />}>
                        <div style={{ display: "inline", fontWeight: "bold" }}>
                            {t.plays.name}
                        </div>
                    </Tooltip> <span style={{ color: secondary }}>({t.from.map(e => e.title).join(", ")})</span></div>)}
                </div>
            </div>
            if (i !== len - 1) {
                return <>{o}<Divider className={classes.divider} /></>;
            } else {
                return o;
            }
        })
    }

    return <Card page={page}>
        <Typography>Please be gentle with the buttons on this page since it uses a free 3rd party API. Only press one button at a time.
        The API recommends sending 1 request every 4 seconds and the voice actor API is one request per anime
            so it is recommended to let the VA download run in the background for a couple of minutes the first time.</Typography>
        <Typography>Currently the only function of this page is to compare voice actors between animes.</Typography>
        <Box pt={2}>
            <Typography>Currently using: {((usage.current ?? 0) / 1000000).toFixed(2)} MB</Typography>
            {wrapTooltip(<Button onClick={() => { localStorage.removeItem(Settings.LocalStorage.MALCache) }}>Clear Storage</Button>,
                "This page uses local storage to store a ton of MAL data, so might want to clear it if you don't plan on using it")}
        </Box>
        <Typography>Current user: <Monospace>{targetUser}</Monospace> Current list length: <Monospace>{currentList.length}</Monospace>
            Number of anime loaded: <Monospace>{Object.keys(storage.anime).length}</Monospace>
        </Typography>
        <Box display="flex" alignItems="center">
            <TextField label="MAL ID" InputProps={{ inputProps: { ref: malIdRef } }} />
            <Box p={1}><Button onClick={async () => {
                const user = malIdRef.current?.value;
                if (user) {
                    const list = await getList(user, storage.anime, page => { setLoadingList(page); })
                    save({ lists: { [user]: list.map(l => l.mal_id) }, anime: Object.fromEntries(list.map(e => [e.mal_id, e])) })
                    setLoadingList(undefined);
                }
            }}>Load</Button></Box>
            {loadingList && <>Loading page {loadingList}<CircularProgress /></>}
        </Box>
        <Box display="flex" alignItems="center">
            <div>Anime with missing VA info <Monospace>{missingVA.length}</Monospace></div>
            <Box p={1}><Button onClick={async () => {
                await downloadVAData(missingVA, (i, va) => {
                    setLoadingVA({ missingVA, i })
                    const mal_id = missingVA[i]
                    const oldAnime = storage.anime[mal_id]
                    save({ anime: { [mal_id]: { ...oldAnime, voice_actors: va } } })
                })
                setLoadingVA(undefined);
            }}>Load</Button></Box>
            {loadingVA !== undefined && <>Loading anime {storage.anime[loadingVA.missingVA[loadingVA.i]].title} ({loadingVA.i + 1}/{loadingVA.missingVA.length})<CircularProgress /></>}
        </Box>
        <Box display="flex" alignItems="center">
            <TextField label="Anime ID" InputProps={{ inputProps: { ref: animeIdRef } }} />
            <Box p={1}><Button onClick={async () => {
                const id = Number(animeIdRef.current?.value)
                if (id) {
                    let va = storage.anime[id]?.voice_actors
                    console.log(va)
                    if (!va) {
                        await downloadVAData([id], (_, data) => {
                            save({ anime: { [id]: { ...storage.anime[id], mal_id: id, voice_actors: data } } })
                            va = data;
                        })
                    }
                    if (!va) return;
                    const vaComparison: VAComparison = { anime: storage.anime[id], comparison: [] }
                    va.forEach(v => {
                        const comparison: Comparison = { source: v, comparisons: [] }
                        const matched: Record<string, any> = {}
                        vaComparison.comparison.push(comparison)
                        for (const key of currentList) {
                            if (key !== id) {
                                const anime = storage.anime[key]
                                anime.voice_actors?.forEach(v2 => {
                                    if (v2.name === v.name && v2.character.name !== v.character.name) {
                                        const m = matched[v2.character.url]
                                        if (m) {
                                            m.from.push(anime);
                                        } else {
                                            const c = {
                                                plays: v2.character,
                                                from: [anime],
                                            }
                                            comparison.comparisons.push(c)
                                            matched[v2.character.url] = c
                                        }
                                    }
                                })
                            }
                        }
                    })
                    setVaComparison(vaComparison);
                }
            }}>Compare</Button></Box>
        </Box>
        {renderVaComparison(vaComparison)}
    </Card>
}