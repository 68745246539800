import React from 'react'
import { makeStyles, Typography } from '@material-ui/core'

interface Props {
    time: number;
}


const dateTimeFormatter = new Intl.DateTimeFormat(undefined, { month: "numeric", day: "numeric", hour: "2-digit", minute: "2-digit" })

const useClasses = makeStyles(theme => ({
    root: {
        display: "flex",
        justifyContent: "center",
        marginTop: theme.spacing(1.5),
        marginBottom: theme.spacing(0.5),
        alignItems: "center"
    },
    timestamp: {
        opacity: 0.6
    },
    divider: {
        flexGrow: 1,
        height: 1,
        background: theme.palette.divider
    }
}))

export default ({ time }: Props) => {
    const classes = useClasses();
    return <div className={classes.root}>
        <div className={classes.divider} />
        <Typography className={classes.timestamp} variant="body2">
            {dateTimeFormatter.format(time)}
        </Typography>
        <div className={classes.divider} />
    </div>
}