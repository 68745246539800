import React from "react";
import { Tooltip, Box } from "@material-ui/core";
import { ReactElement } from "react";

export function wrapTooltip(children: ReactElement, title?: string, wrapperElement?: boolean) {
    if (title) {
        return <Tooltip title={title} PopperProps={{ disablePortal: true }}>
            {wrapperElement ? <Box display="inline-block">{children}</Box> : children}
        </Tooltip>
    } else {
        return children;
    }
}