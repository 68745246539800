import { AuthInfo } from "../components/auth/AuthContextProvider";
import { fetchEndpoint } from "./network";
import Settings from "../data/Settings";
import { Comment } from "../containers/comments/Comment";

export async function postComment(body: string, authInfo: AuthInfo) {
    const result = await fetchEndpoint(Settings.Api.Comment, {
        method: "post", authInfo, requireAuth: true,
        body: { body }
    });

    return result;
}

export async function getComments(authInfo: AuthInfo, page = 0): Promise<Comment[]> {
    const res = await fetchEndpoint(Settings.Api.Comment + "?page=" + page, { authInfo, requireAuth: true })
    if (res) {
        const json = JSON.parse(res);
        return json;
    } else {
        throw new Error("Comment fetch failed")
    }
}

export async function deleteComment(authInfo: AuthInfo, _id: string) {
    const res = await fetchEndpoint(Settings.Api.Comment, { authInfo, requireAuth: true, method: "delete", body: { _id } })
    return res;
}