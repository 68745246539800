import React, { ReactNode, useEffect, useRef, useState, useCallback } from "react";
import Settings from "../../data/Settings";

export interface AuthInfo {
    username: string;
    verified?: boolean;
    admin?: boolean;
    expiration: number;
    token: string;
    hasImage: boolean;
}

export const AuthContext = React.createContext<[AuthInfo | undefined, () => AuthInfo | undefined]>([undefined, () => undefined])

/**
 * Returns the users current AuthInfo based on the client's JWT token, or undefined if no valid JWT token exists.
 * The result from the method should never be trusted. It should always be treated as user provided, even though it is set by the web app.
 */
function getAuthInfo(token: string | null): AuthInfo | undefined {
    if (token !== null) {
        try {
            const dec = JSON.parse(token);
            if (dec.expiration < Date.now()) return undefined;
            const authInfo: AuthInfo = {
                username: dec.username, expiration: dec.expiration, token,
                admin: dec.admin,
                verified: dec.verified,
                hasImage: dec.hasImage
            }
            return authInfo;
        } catch (e) {
            return undefined;
        }
    } else {
        return undefined;
    }
}

export function getDefaultAuthInfo() {
    return getAuthInfo(localStorage.getItem(Settings.LocalStorage.AuthKey))
}

interface Props {
    children: ReactNode
    token?: string | null
}

export default ({ children, token }: Props) => {
    let [authInfo, setAuthInfo] = useState(() => {
        return token === undefined ? getDefaultAuthInfo() : undefined
    });
    if (token !== undefined) authInfo = getAuthInfo(token);

    const forceUpdate = useCallback(() => {
        const authInfo = getAuthInfo(localStorage.getItem(Settings.LocalStorage.AuthKey))
        setAuthInfo(authInfo)
        return authInfo;
    }, [])

    const lastToken = useRef<string | undefined>(undefined);

    lastToken.current = authInfo?.token;
    useEffect(() => {
        function change() {
            if (lastToken.current !== localStorage.getItem(Settings.LocalStorage.AuthKey)) forceUpdate();
        }
        window.addEventListener("storage", change);
        let tokenExpiration: number | undefined = undefined;
        if (authInfo) {
            // if session expires in less than a day, we will wait for the expiry and update
            const expires = authInfo.expiration - Date.now()
            if (expires < 60 * 60 * 24 * 1000) {
                tokenExpiration = window.setTimeout(forceUpdate, expires + 500)
            }
        }
        return () => {
            window.removeEventListener("storage", change);
            if (tokenExpiration !== undefined) {
                window.clearTimeout(tokenExpiration)
            }
        }
    }, [forceUpdate, authInfo])
    return <AuthContext.Provider value={[authInfo, forceUpdate]}>{children}</AuthContext.Provider>
}