import { useEffect } from "react"

export default (message: string, callback: (event: MessageEvent) => void) => {
    useEffect(() => {
        const handler = (e: MessageEvent) => {
            if (e.data === message) {
                callback(e);
            }
        }
        navigator.serviceWorker.addEventListener("message", handler)
        return () => { navigator.serviceWorker.removeEventListener("message", handler) }
    }, [callback, message])
}