import React, { useContext, useRef, useEffect, useState } from 'react'
import { List, ListItem, ListItemAvatar, ListItemText, Box, makeStyles, useTheme, Divider, Paper, TextField, Typography, IconButton, CircularProgress, ListItemIcon, Drawer, InputAdornment } from '@material-ui/core'
import Settings from '../../data/Settings'
import { AuthContext } from '../../components/auth/AuthContextProvider'
import { CSSProperties } from '@material-ui/core/styles/withStyles'
import { Link } from 'react-router-dom'
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import pages from "../../data/pages";
import TimeStamp from './TimeStamp'
import UserAvatar from '../UserAvatar'
import { ChatItem, ChatUser } from './ChatBox'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import NewChatModal from './NewChatModal'
import ReactDOM from 'react-dom'
import { LayoutContext } from '../Layout'
import MenuIcon from '@material-ui/icons/Menu';
import SendIcon from '@material-ui/icons/Send';
import CloseIcon from '@material-ui/icons/Close';

// display driver for ChatBox.tsx


const useClasses = makeStyles(theme => {
    const messageWrapper: CSSProperties = {
        display: "flex",
        alignItems: "flex-end",
        width: "75%"
    }
    const arrowSize = theme.spacing(1);

    const arrow = {
        display: "block",
        position: "absolute",
        content: "\"\"",
        width: 0, height: 0, bottom: 0,
        border: `${arrowSize}px solid transparent`,
    }

    return {
        messaging: {
            display: "flex", flexDirection: "column", flexGrow: 1,
            background: theme.palette.background.default,
            wordBreak: "break-word"
        },
        messages: {
            ...theme.mixins.customScrollbar,
            overflowY: "auto", flex: "1",
            whiteSpace: "pre-wrap",
            display: "flex", flexDirection: "column-reverse",
            paddingLeft: theme.spacing(1),
            paddingRight: theme.spacing(1),
            paddingTop: theme.spacing(1),
            paddingBottom: theme.spacing(1)
        },
        userArea: {
            ...theme.mixins.customScrollbar,
            overflowY: "auto"
        },
        otherMessageWrapper: {
            ...messageWrapper,
            "&>$message": {
                background: theme.palette.secondary.main,
                color: theme.palette.secondary.contrastText
            },
            "&>$profileMessage": {
                borderBottomLeftRadius: 0,
                position: "relative",
                "&::before": {
                    ...arrow,
                    left: -arrowSize,
                    borderBottom: `${arrowSize}px solid ${theme.palette.secondary.main}`
                }
            }
        },
        selfMessageWrapper: {
            ...messageWrapper,
            alignSelf: "flex-end",
            flexDirection: "row-reverse",
            "&>$message": {
                background: theme.palette.common.white,
                color: theme.palette.getContrastText(theme.palette.common.white)
            },
            "&>$profileMessage": {
                borderBottomRightRadius: 0,
                position: "relative",
                "&::before": {
                    ...arrow,
                    right: -arrowSize,
                    borderBottom: `${arrowSize}px solid ${theme.palette.common.white}`
                }
            }
        },
        message: {
            padding: theme.spacing(1)
        },
        profileMessage: {},
        notchedOutline: {
            borderBottom: "none",
            borderRight: "none",
            borderLeft: "none",
            borderRadius: 0
        },
        absolute: {
            position: "absolute!important" as any
        }
    }
})


interface Props {
    fullPageButton?: boolean;
    autoFocus?: boolean;
    messages?: ChatItem[];
    selectedUser?: string;
    users: ChatUser[]
    sendMessage: (message: string) => void
    selectUser: (user: string, isNew: boolean) => void;
    removeUser: (user: string | undefined) => void;
}

export default ({ fullPageButton, autoFocus, messages, users,
    sendMessage, selectUser, removeUser, selectedUser }: Props) => {

    const shrink = useContext(LayoutContext).shrink;

    const classes = useClasses();
    const [authInfo] = useContext(AuthContext);

    const inputRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        if (autoFocus) {
            const input = inputRef.current;
            if (input) input.focus();
        }
    }, [autoFocus])

    const submit = async (e: React.FormEvent) => {
        e.preventDefault();
        const inp = inputRef.current
        if (authInfo && inp && inp.value) {
            const val = inp.value;
            inp.value = ""
            sendMessage(val);
        }
    }

    const theme = useTheme();

    const [newChatModal, setNewChatModal] = useState(false);

    const [mobileDrawer, setMobileDrawer] = useState(false);

    if (!authInfo) return null;

    const selectedUserObj = users.find(e => e.username === selectedUser);

    let lastChat: ChatItem | undefined = undefined;

    let chatDisplay;
    if (messages && messages.length > 0) {
        chatDisplay = messages.map((chat, i) => {
            const hasTime = lastChat && lastChat.time - chat.time > Settings.Chat.TimeDisplayDelay

            const margin = (hasTime || !lastChat) ? 0 : theme.spacing(lastChat.user === chat.user ? 0.25 : 1)

            const avatar = lastChat ? lastChat.user !== chat.user : true;
            let o = <div key={i}
                className={chat.user === undefined ? classes.selfMessageWrapper : classes.otherMessageWrapper}
                style={{ marginBottom: margin }}>
                {avatar ? <>
                    <UserAvatar size={theme.spacing(4)} user={chat.user ?? authInfo?.username}
                        hasImage={chat.user === undefined ? authInfo.hasImage : selectedUserObj?.hasImage} />
                    <Box pr={1} /></> :
                    <Box pr={5} />}
                <Paper
                    className={classes.message + (avatar ? ` ${classes.profileMessage}` : "")}>
                    <Typography>{chat.message}</Typography>
                </Paper>
                {chat.pending && <div style={{ alignSelf: "center" }}><CircularProgress size={24} /></div>}
            </div>;
            if (hasTime && lastChat) {
                o = <React.Fragment key={i}>
                    <TimeStamp time={lastChat.time} />
                    {o}
                </React.Fragment>
            }
            if (i === messages.length - 1) {
                o = <React.Fragment key={i}>
                    {o}
                    <TimeStamp time={chat.time} />
                </React.Fragment>
            }
            lastChat = chat;
            return o
        })
    } else {
        chatDisplay = <div style={{ marginBottom: theme.spacing(0.25) }}>
            <Typography color="textSecondary">{selectedUserObj ?
                `You have no messages with ${selectedUser} yet.` :
                "Please select a user to chat with."}</Typography>
        </div>;
    }

    const mobile = shrink === 2;

    const sideBar = <div className={classes.userArea}>
        <List disablePadding>
            {users.map(user => {
                return <ListItem key={user.username} button
                    selected={user.username === selectedUser}
                    onClick={() => {
                        setMobileDrawer(false);
                        selectUser(user.username, false)
                    }}>
                    <ListItemAvatar>
                        <UserAvatar user={user.username} hasImage={user.hasImage} />
                    </ListItemAvatar>
                    <ListItemText primary={user.username} />
                </ListItem>
            })}
            <ListItem button onClick={e => {
                e.stopPropagation();
                setMobileDrawer(false);
                setNewChatModal(true)
            }} >
                <ListItemIcon>
                    <AddCircleOutlineIcon fontSize="large" style={{ width: 40, height: 40 }} />
                </ListItemIcon>
                <ListItemText primary="New chat" />
            </ListItem>
        </List>
    </div>;

    return <>
        {mobile && mobileDrawer && <Drawer disablePortal anchor="right"
            transitionDuration={0}
            BackdropProps={{ classes: { root: classes.absolute } }}
            classes={{ root: classes.absolute, paper: classes.absolute }}
            open={true} onClose={() => setMobileDrawer(false)}>
            {sideBar}
        </Drawer>}
        <div style={{ display: "flex", height: "100%" }}>
            {newChatModal && <NewChatModal
                onClose={e => {
                    ReactDOM.unstable_batchedUpdates(() => {
                        setNewChatModal(false);
                        if (e) {
                            selectUser(e, true);
                        }
                    })
                }} />}

            {!mobile && sideBar}
            {!mobile && <Divider flexItem orientation="vertical" />}
            <div className={classes.messaging}>
                <Box style={{ backgroundColor: theme.palette.background.paper, boxShadow: theme.shadows[1] }} display="flex" alignItems="center">
                    <Box p={1}>
                        <UserAvatar user={selectedUser}
                            hasImage={selectedUserObj?.hasImage} />
                    </Box>
                    <Typography variant="h6">{selectedUser}</Typography>
                    <div style={{ flex: 1 }}></div>
                    <IconButton onClick={() => removeUser(selectedUser)}><CloseIcon /></IconButton>
                    {fullPageButton && <Link to={pages.chat.path}>
                        <IconButton>
                            <OpenInNewIcon />
                        </IconButton>
                    </Link>}
                    {mobile && <Box p={1}>
                        <IconButton onClick={() => setMobileDrawer(true)}><MenuIcon /></IconButton>
                    </Box>}
                </Box>
                <Box className={classes.messages}>{chatDisplay}</Box>
                <Box>
                    <form onKeyDown={e => {
                        if (e.key === "Enter" && !e.shiftKey) submit(e)
                    }} onSubmit={submit}>
                        <TextField placeholder="Write something beautiful" multiline fullWidth variant="outlined"
                            rowsMax={4}
                            InputProps={{
                                classes: { notchedOutline: classes.notchedOutline },
                                endAdornment: <InputAdornment position="end">
                                    <IconButton onClick={e => submit(e)}><SendIcon /></IconButton>
                                </InputAdornment>
                            }}
                            inputRef={inputRef} />
                    </form>
                </Box>
            </div>
        </div></>
}