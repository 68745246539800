import React from 'react'
import { makeStyles, Box } from '@material-ui/core'
import Card from '../../containers/Card'
import useEndpoint from '../../hooks/network/useEndpoint'
import Settings from '../../data/Settings'

interface Image {
    createdOn: number;
    username: string;
    filename: string;
}

const useStyles = makeStyles(theme => ({
    img: {
        width: theme.spacing(32),
        height: theme.spacing(32),
        display: "block"
    }
}));

export default () => {
    const classes = useStyles();
    const [images] = useEndpoint<Image[]>(Settings.Api.Image, { localStorageKey: Settings.LocalStorage.Images })


    return <>{images && images.map(i => {
        return <Card key={i.filename} username={i.username} hasImage={true} noWidth noPadding>
            <Box display="flex">
                <Box display="inline-block">
                    <a href={`/image/${i.filename}`}>
                        <img src={`/image/thumb/${i.filename.split(".")[0]}.webp`} className={classes.img} alt={i.username} />
                    </a>
                </Box>
            </Box>
        </Card>
    })}</>
}