import React, { useState } from "react"
import ConfirmationModal from "../components/ConfirmationModal"
import { TextField, Box, Tooltip } from "@material-ui/core"
import InfoIcon from "@material-ui/icons/Info"

interface Props {
    onClose: (changed: boolean) => void;
}

const nameTooltip = "Name used to activate the emote. Case sensitive. Can only contain letters, _, or numbers"
const aliasTooltip = "Alternatives to the name listed above. Separate with new line."

const invalidNameCharacters = /\W/g
// the API will use a similar function after submitting, this is just to help users
function clean(name: string) {
    return name.replace(invalidNameCharacters, "");
}

export default ({ onClose }: Props) => {
    const [name, setName] = useState("")
    const [aliases, setAliases] = useState<string[]>([])

    return <ConfirmationModal onClose={() => onClose(false)} title="Create New Emote" confirm="Submit" deny="Cancel">
        <Box p={1}><TextField label="Name" variant="filled" value={name} required fullWidth
            InputProps={{ endAdornment: <Tooltip title={nameTooltip}><InfoIcon /></Tooltip> }}
            onChange={(e) => setName(clean(e.target.value))} /></Box>
        <Box p={1}><TextField label="Aliases" variant="filled" value={aliases.join('\n')} fullWidth multiline
            onChange={(e) => setAliases(e.target.value.split('\n').map(clean))}
            InputProps={{ endAdornment: <Tooltip title={aliasTooltip}><InfoIcon /></Tooltip> }} /></Box>
        <Box p={1}><TextField label="Image" variant="filled" value="Technology doesn't exist yet 😢" disabled
            required fullWidth multiline /></Box>
    </ConfirmationModal>
}