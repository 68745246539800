import MarkdownIt from "markdown-it"
import type { Match } from "linkify-it"

const subreddit = /^[a-zA-Z0-9-_]{3,20}\/?/
const reddit = "https://reddit.com"
const normalizeReddit = (match: Match) => {
    if (match.text.charAt(match.text.length - 1) !== "/") match.text = `${match.text}/`
    match.url = `${reddit}${match.text}`
}

// Enables reddit links on a MarkdownIt object
export default (md: MarkdownIt) => {
    md.linkify.add("/r/", {
        validate: subreddit,
        normalize: normalizeReddit
    })
    md.linkify.add("r/", {
        validate: subreddit,
        normalize: match => {
            match.text = `/${match.text}`
            normalizeReddit(match)
        }
    })
}