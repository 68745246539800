import React, { useContext, useState } from "react"
import Card from "../../containers/Card"
import { Page } from "../../data/pages"
import { Typography, makeStyles, Table, TableCell, TableHead, TableBody, TableRow, Box, IconButton } from "@material-ui/core"
import { emoteBaseStyle, getStyles } from "../../util/markdown/Emotes"
import Emotes, { EmoteName } from "../../data/Emotes"
import AddCircleIcon from '@material-ui/icons/AddCircle';
import { AuthContext } from "../../components/auth/AuthContextProvider"
import NewEmoteModal from "../../containers/NewEmoteModal"

interface Props {
    page: Page
}

const useClasses = makeStyles({
    emote: emoteBaseStyle
})


export default ({ page }: Props) => {
    const classes = useClasses();

    const [showNewModal, setShowNewModal] = useState(false);

    // Could cache this but it should be pretty cheap to recompute when rendering this page
    // A rerender only triggers when someone clicks the add emote button anyways
    const aliasListing = {} as { -readonly [key in EmoteName]: string[] };
    (Object.keys(Emotes.emotes) as EmoteName[]).forEach(e => { aliasListing[e] = []; });
    (Object.entries(Emotes.aliases) as [string, EmoteName][]).forEach(([k, v]) => { aliasListing[v].push(k) })

    const [authInfo,] = useContext(AuthContext);

    return <Card>
        {showNewModal && <NewEmoteModal onClose={() => setShowNewModal(false)} />}
        <Box right="0" top="0" position="absolute">
            {authInfo?.admin && <IconButton onClick={() => setShowNewModal(true)}>
                <AddCircleIcon />
            </IconButton>}
        </Box>
        <Typography variant="h4" align="center">{page.label}</Typography>
        <Table>
            <TableHead>
                <TableCell>Image</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Aliases</TableCell>
            </TableHead>
            <TableBody>
                {(Object.keys(Emotes.emotes) as EmoteName[]).sort().map(key => <TableRow key={key}>
                    <TableCell><span className={classes.emote} style={getStyles(key)}></span></TableCell>
                    <TableCell>{key}</TableCell>
                    <TableCell>{aliasListing[key].join(", ")}</TableCell>
                </TableRow>)}
            </TableBody>
        </Table>
    </Card>
}